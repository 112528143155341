import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap"
import CustomTable from "components/customTable/index.tsx";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { getTimezoneFormatUtc } from "common/utils.ts";
import * as XLSX from "xlsx";

import {
  DEFAULT_ERROR_MESSAGE,
  ITEMS_PER_PAGE,
} from "common/constants";
import request from "services/request";
import SortFilter from "components/customTable/sortFilter";
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";
import TelechargerIcon from "assets/images/telecharger.png";

function LeftSide({ formData, clear }) {
  const { setLoading, setError } = useLoading();
  const { t } = useTranslation();
  const {
    selectedRestaurant,
    selectedRestaurantId,
    setFilterShown,
    isFilterShown 
  } = useUserData();
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [forecastingLoading, setForecastingLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [restaurants, setRestaurants] = useState([]);
  const [fd, setFd] = useState({
    orderBy: "desc",
    sortBy: "stock.unit_stock",
  });
  const tableRef = useRef(null);

  const fetchAndSetOrder = async () => {
    try {
      setForecastingLoading(true);
      let res = await request.get(`labos/restaurants`, {labo_id: selectedRestaurantId});
      let restaurantsData = res.restaurants.map(res => res.id)
      restaurantsData = formData.restaurants.length ? formData.restaurants : restaurantsData
      setRestaurants(restaurantsData);
      const result = await getOrders(restaurantsData);
      setForecastingLoading(false);
      setCurrentItems(result.meals);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    fetchAndSetOrder();
  }, [JSON.stringify(formData), selectedRestaurantId])

  const getOrders = (restaurants = [], page = 1, is_initial = false) => {
    const comparisonDates = formData?.comparison_start_date && formData?.comparison_end_date
    ? getTimezoneFormatUtc(formData.comparison_start_date, formData.comparison_end_date, selectedRestaurant?.timezone)
    : {};

    const payload = {
      labo_id : selectedRestaurantId,
      restaurants,
      start_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.start_date,
      end_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.end_date,
      ...(comparisonDates.start_date && { comparison_start_date: comparisonDates.start_date }),
      ...(comparisonDates.end_date && { comparison_end_date: comparisonDates.end_date }),
      meals: formData.meals ?? [],
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
    };
    return request.get(`labos/sales-forecasting`, payload);
  };

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      const result = await getOrders(restaurants,event.selected + 1);
      setCurrentPage(event.selected);
      setCurrentItems(result.meals);
      setPageCount(result.total_pages);
      setLoading(false);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleExport = () => {
    if (tableRef.current) {
      // Get the table DOM element
      const table = tableRef.current;

      // Convert the table to a worksheet
      const worksheet = XLSX.utils.table_to_sheet(table);

      // Create a workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook and trigger the download
      XLSX.writeFile(workbook, "TableData.xlsx");
    }
  };

  const generateSalesForecastColumns = () => {
    // Determine the label based on formData values
    let periodLabel = "";
    if (formData?.year) {
      periodLabel = "Year-1";
    } else if (formData?.month) {
      periodLabel = "Month-1";
    } else if (formData?.week) {
      periodLabel = "Week-1";
    }
  
    // Get unique restaurant names from the sales_by_restaurants data
    const restaurantNames = [
      ...new Set(currentItems.flatMap(item => item.sales_by_restaurants.map(rest => rest.restaurant_name)))
    ];
  
    // Generate the columns for restaurants dynamically
    const restaurantColumns = restaurantNames.map(restaurantName => ({
      caption: restaurantName,
      dataField: restaurantName.replace(/\s+/g, "_"), // Use a formatted dataField for the restaurant name
      className: "text-center",
      headerClassName: "text-center",
      headerStyle: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "18px",
        color: "#000000",
        height: "85px",
        whiteSpace: "normal",
      },
      type: "customRender",
      render: (column, item) => {
        // Find the restaurant data for this specific row
        const restaurantData = item.sales_by_restaurants.find(rest => rest.restaurant_name === restaurantName);
  
        if (!restaurantData) return;
  
        const { sales, comparison_sales } = restaurantData;
  
        // Calculate percentage change safely
        const percentageChange = ((sales - comparison_sales) / (comparison_sales || 1)) * 100;
        const displayPercentage = Math.round(percentageChange);
  
        const percentageColor = sales >= comparison_sales ? "#8ACC0B" : "#FF4D4D";
  
        return (
          <div>
            <span className="main-value actual-value" style={{ fontSize: "17px", fontWeight: "bold", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
              {sales}
            </span>
            {!!comparison_sales && (
              <div style={{ display: "flex", justifyContent: "center", gap: "14px" }}>
                <div style={{ color: percentageColor, display: "flex", alignItems: "center" }}>
                  <span className="value">{displayPercentage}%</span>
                </div>
                <div style={{ color: percentageColor }}>
                  <span className="value">{comparison_sales}</span>
                </div>
              </div>
            )}
          </div>
        );
      },
    }));
  
    return [
      {
        caption: t("Name"),
        dataField: "name",
        type: "string",
        headerStyle: {
          width: "120px",
          fontWeight: "700",
          textAlign: "center",
          fontSize: "19px",
        },
        style: { fontSize: "21px", fontWeight: "600", color: "#292D32", textAlign: "center" },
        active: false,
        type: "customRender",
        render: (column, item) => {
          const { name } = item;
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="main-value actual-value forecast-name">{name}</span>
              <span className="period-label">{t(periodLabel)}</span>
            </div>
          );
        },
      },
      {
        caption: <div><div>{t("TOTAL")}</div><div>{t("Realised")}</div></div>,
        dataField: "total_realised",
        type: "number",
        style: { textAlign: "center" },
        headerClassName: "text-center actual-header",
        headerStyle: {
          fontWeight: "600",
          textAlign: "center",
          fontSize: "19px",
          whiteSpace: "pre-line"
        },
        active: true,
        type: "customRender",
        render: (column, item) => {
          // Find the restaurant data for this specific row
          const total_actual_sales = item?.sales_by_restaurants
            .map((rest) => rest.actual_sales || 0)
            .reduce((a, b) => a + b, 0);
          
          const total_comparison_sales = item?.sales_by_restaurants
            .map((rest) => rest.comparison_sales || 0)
            .reduce((a, b) => a + b, 0);
    
          const percentageChange = ((total_actual_sales - total_comparison_sales) / (total_comparison_sales || 1)) * 100;
          const displayPercentage = Math.round(percentageChange);

          const percentageColor = total_actual_sales >= total_comparison_sales ? "#8ACC0B" : "#FF4D4D";
    
          return (
            <div>
              <span className="main-value actual-value" style={{ fontSize: "17px", fontWeight: "bold" }}>
                {total_actual_sales}
              </span>
              {!!total_comparison_sales && (
                <div style={{ display: "flex", justifyContent: "center", gap: "14px" }}>
                  <div style={{ color: percentageColor, display: "flex", alignItems: "center" }}>
                    <span className="value">{displayPercentage}%</span>
                  </div>
                  <div style={{ color: percentageColor }}>
                    <span className="value">{total_comparison_sales}</span>
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
      {
        caption: <div><div>{t("TOTAL")}</div><div>{t("Forecast")}</div></div>,
        dataField: "total_forecast",
        type: "number",
        style: { textAlign: "center" },
        headerClassName: "text-center predicted-header",
        headerStyle: {
          fontWeight: "600",
          textAlign: "center",
          fontSize: "19px",
          whiteSpace: "pre-line"
        },
        active: true,
        type: "customRender",
        render: (column, item) => {
          const total_predicted_sales = item?.sales_by_restaurants.map((rest) => rest.predicted_sales).reduce((a, b) => a + b, 0);
          return (
            <div style={{ fontSize: "17px", fontWeight: "600", color: "#873CFC" }}>
              <div>{total_predicted_sales}</div>
            </div>
          );
        }
      },
      ...restaurantColumns, // Add the dynamically generated restaurant columns
    ];
  };

  useEffect(() => {
    if (true) {
      setFd((prevFd) => ({ ...prevFd, sortBy: "product" }));
    }
  }, [true])

  let getSortedItems = (val) => {
    setCurrentItems(currentItems.sort((a, b) => a[val] - b[val]));
  };

  let selecteSalesColumns = generateSalesForecastColumns();

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ marginBottom: "20px" }}>
        <Col className="d-flex justify-content-end">
          <button  className="btn btn-white btn-icon me-1" style={{ padding: "2px 13px", height: "38px"}}>
            <img  onClick={handleExport} src={TelechargerIcon} alt="" className="m-0" />
          </button>
          <SortFilter
            cols={generateSalesForecastColumns()}
            fd={fd}
            setFd={setFd}
            getSortedItems={getSortedItems}
            rootClassName="sort-filter ps-3"
          />

          {!isFilterShown && (
            <div className="headerbtns ms-3">
              <button
                onClick={() => setFilterShown(true)}
                className="btn btn-white btn-icon btn-theme"
                style={{
                  height:"35px"
                }}
              >
                <img src={FilterIcon} alt="" className="m-0" />
              </button>
            </div>
          )}
        </Col>
      </Row>

      <div className="leftcontent leftcontent-stock sales-forecast">
        <div style={{ backgroundColor: "transparent"}} className="card m-10 mb-0">
          {forecastingLoading && (
            <div className="w-100 d-flex justify-content-center card-spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          )}

          {!forecastingLoading &&
            <div className="card-body">
              <div className="sales-forecast-wrapper">
                <div className="sales-flex-wrapper">
                  <div style={{ width: "100%" }}>
                    <CustomTable columns={selecteSalesColumns} ref={tableRef} data={currentItems} tableName="sales-forecast-table"/>
                  </div>
                </div>
                <Row>
                  <div className="d-flex justify-content-end mt-2">
                    <ReactPaginate
                      nextLabel={`${t("Next")}   >`}
                      onPageChange={handlePageClick}
                      forcePage={currentPage}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={`<   ${t("Back")}`}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </Row>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default LeftSide;