import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { cloneDeep } from "common/utils.ts";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";
import { useTranslation } from "react-i18next";

import request from "services/request";

import SelectAsyncPaginate from "components/selectAsyncPaginate";
import CustomModal from "components/ingredientListModel";

import CloseIcon from "assets/images/close.png";
import tableIngredientsDummy from "../../data/ingredients.json";
import "react-datepicker/dist/react-datepicker.css";

function RightSide({ onApply: onSubmit, formData: fd, setClear }) {
  const { t } = useTranslation();
  const {
    filterFormData,
    setFilterFormData,
    isFilterActive,
    updateFilterButton,
    selectedIngredients, 
    setSelectedIngredients, 
  } = useFilterData();
  const [ingredientList, setIngredientList] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [formData, setFormData] = useState(filterFormData ?? { });
  const [selectedIngredientOption, setSelectedIngredientOption] = useState(selectedIngredients ?? []);
  
  const { isFilterShown, setFilterShown, selectedRestaurant, selectedRestaurantId } = useUserData();

  useEffect(() => {
    // prevSelectedRestaurantId.current = selectedRestaurantId;
    onClearAll();
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (!selectedRestaurantId) return;
    if (isModal === false) {
      const getIngredientsList = async () => {
        const result = await request.get("ingredient-lists", {
          restaurant_id: selectedRestaurantId
        });
        setIngredientList(result.ingredient_lists);
      };
      getIngredientsList();
    }
  }, [isModal, selectedRestaurantId]);

  const options = tableIngredientsDummy.ingredients?.map((m) => ({
    name: m.name,
    label: m.name,
    item_id: m.name,
  }));

  const onApply = (isInitial = false) => {
    const payload = cloneDeep({ ...formData });

    if (selectedIngredientOption?.length > 0) {
      payload.ingredients = selectedIngredientOption.map((ingredient) => {
        if (Array.isArray(ingredient.details) && ingredient?.details?.length > 0 && ingredient.details.every((id) => typeof id === "string")) {
          return ingredient.details;
        } else {
          return ingredient.id;
        }
      }).flat(); // Flatten the array if `details` array is nested inside
    }

    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.ingredients = [];
    }

    onSubmit(payload);
  };

  const onClearIngredients = () => {
    setSelectedIngredientOption([]);
  };

  const onClearAll = (apply = true) => {
    onClearIngredients(); 
    setFormData({});
    setClear(true);
    onApply(true);
  };

  const onSaveFilter = () => {
    setSelectedIngredients(selectedIngredientOption);
    setFilterFormData(formData); 
    updateFilterButton(true);
  }

  const onIngredientSelect = () => (v) => {
    setSelectedIngredientOption([...v]);
  };

  const createList = () => {
    setIsModal(true);
  };

  const onModalHide = () => {
    setIsModal(false);
  };

  return (
    <>
      <CustomModal show={isModal} onHide={onModalHide} />
      {isFilterShown && (
        <div className="rightcontent">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")}
                  </div>
                </Col>
              </Row>

              <div className="rightcontent-fltr">
                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Ingredients")}</label>
                    <button onClick={onClearIngredients}>{t("Clear")}</button>
                  </div>
                  <SelectAsyncPaginate
                    {...(!selectedRestaurant && {
                      datasource: options,
                    })}
                    placeholder={t("Select Ingredients")}
                    onChange={onIngredientSelect}
                    value={selectedIngredientOption}
                    count={selectedIngredientOption.length}
                    isMulti
                    query="ingredients"
                    key={(ingredientList ?? []).join("_")}
                    multiOptions={ingredientList}
                  />
                  
                  <label onClick={createList} className="mt-3 fw-bold">
                    {t("ManageList")}
                  </label>
                </div>
              </div>

              <div className="action-btns" style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}>
                  <div 
                    style={{
                      marginBottom : "7px"
                    }}
                  >
                    <button onClick={onSaveFilter} className="close-btn me-3" 
                      style={{
                        width : "100%",
                        color : isFilterActive ? "#8b8f94" : "#6353ea"
                      }}
                    >
                      {t("FixFilters")}
                    </button>
                  </div>
                  <div style={{
                    display:"flex"
                  }}>
                    <button onClick={onClearAll} className="close-btn me-3">
                      <img src={CloseIcon} alt="" />
                      {t("Clear")}
                    </button>
                    <button onClick={onApply} className="apply-btn">
                      {t("Apply")}
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RightSide;