import React, { useState, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import request from "services/request";

import CustomModal from "components/mealListModel";
import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";

import CloseIcon from "assets/images/close.png";
import CalendarIcon from "assets/images/calendar_datepicker.png";
import OrderStatusIcon from "assets/images/icon/order-status.svg";
import orderData from "../../../data/orders.json"
import "react-datepicker/dist/react-datepicker.css";

const COMPARE_CHECKBOXES = [
  { name: "year", label: "Year-1"},
  { name: "month", label: "Month-1"},
  { name: "week", label: "Week-1"}
];

/**
 * function to render the filter screen of the sctock page
 * @param {*} param0
 * @returns UI component
 */
const START_DATE = new Date(new Date().setDate(new Date().getDate() - 10));
const END_DATE = new Date(new Date().setDate(new Date().getDate() + 10));

function RightSide({ onApply: onSubmit, formData: fd, setClear }) {
  const { t } = useTranslation();
  const {
    isFilterShown,
    setFilterShown,
    selectedRestaurant,
    selectedRestaurantId,
  } = useUserData();

  const {
    filterStartEndDate,
    setFilterStartEndDate,
    selectedFilterProucts,
    setSelectedFilterProducts,
    isFilterActive,
    updateFilterButton
  } = useFilterData();

  const [selectedOption, setSelectedOption] = useState(selectedFilterProucts ?? []);
  const [selectedMealOption, setSelectedMealOption] = useState(selectedFilterProucts ?? []);
  const [mealsList, setMealsList] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [formData, setFormData] = useState({
    year: true,
    month: false,
    week: false,
    start_date: moment().clone().subtract(10, "days").format("YYYY-MM-DD"),
    end_date : moment().clone().add(10, "days").format("YYYY-MM-DD")
  });
  const [startEndDate, setStartEndDate] = useState({
    start_date: filterStartEndDate && filterStartEndDate.start_date !== "" ? filterStartEndDate.start_date : START_DATE,
    end_date: filterStartEndDate && filterStartEndDate.end_date !== "" ? filterStartEndDate.end_date : END_DATE,
  });

  /**
   * to be called whenever the user selects the date from date-window
   * @param {*} name field name
   * @returns
   */
  const onDateChange = (name) => (e) => {
    setStartEndDate({ ...startEndDate, [name]: e });
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  let dummyPOSDatasource = useMemo(
    () =>
      orderData.purchase_orders.map((m) => ({
        value: m.restaurant,
        label: m.restaurant,
        name: m.restaurant,
        item_id: m.restaurant_id,
      })),
    []
  );

  useEffect(() => {
    onClearAll();
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (!selectedRestaurantId) return;
    if (isModal === false) {
      const getMealsList = async () => {
        const result = await request.get("meal-lists", {
          restaurant_id: selectedRestaurantId
        });
        setMealsList(result.meal_lists);
      };
      getMealsList();
    }
  }, [isModal, selectedRestaurantId]);

  /**
   * submits the selected filters to api
   * @param {*} isInitial
   */

  const onApply = (isInitial = false) => {
    const payload = JSON.parse(
      JSON.stringify({ ...formData, ...startEndDate })
    );
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (payload.start_date) {
      payload.start_date = getFormattedDate(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = getFormattedDate(payload.end_date);
    }
    // If year is true, set comparison_start_date and comparison_end_date
    if (payload.year) {
      if (payload.start_date && payload.end_date) {
        payload.comparison_start_date = moment(payload.start_date)
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        payload.comparison_end_date = moment(payload.end_date)
          .subtract(1, "year")
          .format("YYYY-MM-DD");
      }
    } else if (payload.month) {
      if (payload.start_date && payload.end_date) {
        payload.comparison_start_date = moment(payload.start_date)
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        payload.comparison_end_date = moment(payload.end_date)
          .subtract(1, "month")
          .format("YYYY-MM-DD");
      }
    } else if (payload.week) {
      if (payload.start_date && payload.end_date) {
        payload.comparison_start_date = moment(payload.start_date)
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        payload.comparison_end_date = moment(payload.end_date)
          .subtract(1, "week")
          .format("YYYY-MM-DD");
      }
    }
    
    if (selectedMealOption?.length) {
      payload.meals = selectedMealOption
        .map((meal) => {
          // If meal.details exists and contains objects with meal_id, extract meal_id
          if (Array.isArray(meal?.details) && meal.details.length && meal.details.every((item) => item.meal_id)) {
            return meal.details.map((item) => item.meal_id);
          } else {
            return meal.id;
          }
        })
        .flat(); // Flatten if meal_id arrays are nested
    }

    payload.restaurants = selectedOption.map((s) => s.id);

    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.meals = []; 
      payload.restaurants = [];
      payload.start_date =  moment().clone().subtract(10, "days").format("YYYY-MM-DD");
      payload.end_date = moment().clone().add(10, "days").format("YYYY-MM-DD");
    }

    onSubmit(payload);
  };

  const onDropdownCheckboxChange = (name) => (e) => {
    const checked = e.target.checked;
    setFormData((prevData) => {
      const updatedData = { year: false, month: false, week: false };
      updatedData[name] = checked;
      return updatedData;
    });
  };

  const customCheckbox = ({ name, label, checked }) => (
    <div style={{ display: "flex", alignItems: "center", padding: "5px 10px" }} key={name}>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={onDropdownCheckboxChange(name)}
        style={{
          marginRight: "10px",
          appearance: "none",
          width: "20px",
          height: "20px",
          border: "2px solid #E0E0E0",
          borderRadius: "3px",
          backgroundColor: checked ? "rgb(99, 83, 234)" : "#E0E0E0",
          position: "relative",
          cursor: "pointer"
        }}
      />
      <label htmlFor={name} style={{ margin: 0 }}>
        {label}
      </label>
      <style>
        {`
          input[type="checkbox"]:checked::before {
            content: "✔";
            color: white;
            font-weight: bold;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>
    </div>
  );

  const onClearDates = () => {
    setStartEndDate({
      start_date: moment().clone().subtract(10, "days").format("YYYY-MM-DD"),
      end_date : moment().clone().add(10, "days").format("YYYY-MM-DD")
    });
  };

  const onClearAll = () => {
    onClearDates();
    onClearProducts();
    setFormData({ year: true, month: false, week: false, 
      start_date: moment().clone().subtract(10, "days").format("YYYY-MM-DD"),
      end_date : moment().clone().add(10, "days").format("YYYY-MM-DD")
    });
    onApply(true);
  };

  const handleClear = () =>{
    onClearAll();
    setClear(true);
  }

  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

  const onClearProducts = () => {
    setSelectedOption([]);
    setSelectedMealOption([]);
  };

  useEffect(() => {
    if (filterStartEndDate && filterStartEndDate.start_date !== "" && filterStartEndDate.end_date !== "") {
      setStartEndDate(filterStartEndDate);
    } else {
      setStartEndDate(startEndDate);
    }  
    setSelectedOption(selectedFilterProucts);
    onApply();
  }, [])

  const onIngredientSelect = (type) => (v) => setSelectedOption([...v]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const onSelectOption = (dataField) => (v) => {
    if (dataField === "meal") {
      setSelectedMealOption([...v]);
    }
  };

  const createList = () => {
    setIsModal(true);
  };

  const onModalHide = () => {
    setIsModal(false);
  };

  return (
    <>
      <CustomModal show={isModal} onHide={onModalHide} />
      {isFilterShown && (
        <div className="rightcontent">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")}
                  </div>
                </Col>
              </Row>

              <div className="rightcontent-fltr">
                <div className="mb-3 meals-dropdown">
                  <div className="fltr-heading">
                    <label>{t("PointOfSale")}</label>
                    <button onClick={onClearProducts}>{t("Clear")}</button>
                  </div>
                  <SelectAsyncPaginate
                    {...(!selectedRestaurant && {
                      datasource: dummyPOSDatasource,
                    })}
                    dataField="pos"
                    placeholder={t("Select")}
                    onChange={onIngredientSelect}
                    value={selectedOption}
                    count={selectedOption.length}
                    isMulti={true}
                    query="labos/restaurants"
                    key={([]).join("_")}
                    // multiOptions={[]}
                  />
                </div>

                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Meals")}</label>
                    <button onClick={onClearProducts}>{t("Clear")}</button>
                  </div>
                    <SelectAsyncPaginate
                      dataField="meal"
                      placeholder={t("SelectMeals")}
                      onChange={onSelectOption}
                      value={selectedMealOption}
                      count={selectedMealOption.length}
                      isMulti
                      query="meals"
                      key={(mealsList ?? []).join("_")}
                      multiOptions={mealsList}
                    />
                    <label onClick={createList} className="mt-3 fw-bold">
                      {t("ManageList")}
                    </label>
                </div>

                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Date")}</label>
                    <button onClick={onClearDates}>{t("Clear")}</button>
                  </div>
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("Start")}</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={new Date(startEndDate.start_date)}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("Start date")}
                        onChange={onDateChange("start_date")}
                        className="date-picker"
                        open={isStartDateOpen}
                        onClickOutside={setDateOpen("start_date")}
                        onClick={setDateOpen("start_date", true)}
                      // minDate={new Date()}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("start_date", !isStartDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                  <hr className="hr-separator" />
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("End")}&nbsp;&nbsp;</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={new Date(startEndDate.end_date)}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("End date")}
                        onChange={onDateChange("end_date")}
                        className="date-picker"
                        open={isEndDateOpen}
                        onClickOutside={setDateOpen("end_date")}
                        onClick={setDateOpen("end_date", true)}
                        // maxDate={maxEndDate}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("end_date", !isEndDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-5 compare-checkbox">
                  <Dropdown align="end">
                    <Dropdown.Toggle
                      style={{ width: "100%" }}
                      variant="button"
                      className="btn btn-white dropdown-toggle btn-icon"
                    >
                      <span style={{ color: "rgb(99, 83, 234)", fontWeight: "bold" }}>
                        <img src={OrderStatusIcon} alt="order-status" />
                        {t("Compare with...")}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {COMPARE_CHECKBOXES.map((option) => {
                        return customCheckbox({
                          ...option,
                          checked: formData[option.name],
                          label: t(option.label),
                        });
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="action-btns" style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}>
                <div style={{
                  display: "flex"
                }}>
                  <button onClick={handleClear} className="close-btn me-3">
                    <img src={CloseIcon} alt="" />
                    {t("Clear")}
                  </button>
                  <button onClick={onApply} className="apply-btn">
                    {t("Apply")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RightSide;
