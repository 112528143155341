import { useState, useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import request from "services/request";

import { cloneDeep } from "common/utils";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";

import CloseIcon from "assets/images/close.png";
import CalendarIcon from "assets/images/calendar_datepicker.png";

import "react-datepicker/dist/react-datepicker.css";
import SelectAsyncPaginate from "components/selectAsyncPaginate";
import { MEAL_CATEGORIES } from "common/constants";
import SelectInput from "components/selectInput";
import CustomModal from "components/mealListModel";
// import sample_ingredients_data from "../../data/stock_predictions.json";

const STATUS_CHECKBOXES = [
  { id: "1", name: "sent", label: "Sent" },
  { id: "2", name: "in-preparation", label: "In Preparation" },
  { id: "0", name: "cancelled", label: "Cancelled" },
];

const EXPIRY_CHECKBOXES = [
  { name: "good_to_eat", label: "Good to eat" },
  { name: "soon_to_expire", label: "Soon to be expired" },
  { name: "expired", label: "Expired" },
];

/**
 * function to render the filter screen of the sctock page
 * @param {*} param0
 * @returns UI component
 */
const START_DATE = new Date(new Date().setDate(new Date().getDate()));
const END_DATE = new Date(new Date().setDate(new Date().getDate()+ 14));

function RightSide({ onApply: onSubmit, formData: fd, setClear }) {
  const { t } = useTranslation();
  const {
    isFilterShown,
    setFilterShown,
    selectedRestaurantId,
  } = useUserData();

  const {
    filterStartEndDate,
    selectedFilterProucts,
  } = useFilterData();

  const [selectedMealOption, setSelectedMealOption] = useState(selectedFilterProucts ?? []);
  const [selectedMealTypeOption, setSelectedMealTypeOption] = useState({});
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [mealsList, setMealsList] = useState([]);
  const [formData, setformData] = useState({});
  const [startEndDate, setStartEndDate] = useState({
    start_date: filterStartEndDate && filterStartEndDate.start_date !== "" ? filterStartEndDate.start_date : START_DATE,
    end_date: filterStartEndDate && filterStartEndDate.end_date !== "" ? filterStartEndDate.end_date : END_DATE,
  });

  /**
   * to be called whenever the user selects the date from date-window
   * @param {*} name field name
   * @returns
   */
  const onDateChange = (name) => (e) => {
    const maxEndDate = e < today ? new Date(today.getTime() - (24 * 60 * 60 * 1000)) : null;
    if (name == "start_date" && maxEndDate){
      setStartEndDate({ ...startEndDate, end_date: maxEndDate , [name]: e});
    } else {
      setStartEndDate({ ...startEndDate, [name]: e });
    }
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  useEffect(() => {
    onClearAll();
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (!selectedRestaurantId) return;
    if (isModal === false) {
      const getMealsList = async () => {
        const result = await request.get("meal-lists", {
          restaurant_id: selectedRestaurantId
        });
        setMealsList(result.meal_lists);
      };
      getMealsList();
    }
  }, [isModal, selectedRestaurantId]);

  const onSelectOption = (dataField) => (v) => {
    if (dataField === "meal") {
      setSelectedMealOption([...v]);
    }
    if (dataField === "meal_type") {
      setSelectedMealTypeOption({...v});
    }
  };

  /**
   * submits the selected filters to api
   * @param {*} isInitial
   */
  const onApply = (isInitial = false) => {
    const payload = JSON.parse(
      JSON.stringify({ ...formData, ...startEndDate })
    );
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (payload.start_date) {
      payload.start_date = getFormattedDate(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = getFormattedDate(payload.end_date);
    }
   
    // Remove extracted keys from formData
    STATUS_CHECKBOXES.forEach((item) => {
      if (payload[item.name] !== undefined) {
        delete payload[item.name];
      }
    });

    // if(selectedMealOption.length > 0) {
    //   payload.meals = selectedMealOption.map((s) => s.id);
    // }
    if (selectedMealOption?.length) {
      payload.meals = selectedMealOption
        .map((meal) => {
          // If meal.details exists and contains objects with meal_id, extract meal_id
          if (Array.isArray(meal?.details) && meal.details.length && meal.details.every((item) => item.meal_id)) {
            return meal.details.map((item) => item.meal_id);
          } else {
            return meal.id;
          }
        })
        .flat(); // Flatten if meal_id arrays are nested
    }

    if(Object.keys(selectedMealTypeOption).length > 0) {
      payload.meal_types = [selectedMealTypeOption.id];
    }
    
    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.start_date = moment().format("YYYY-MM-DD");
      payload.end_date = moment().clone().add(14, "days").format("YYYY-MM-DD");
      payload.meals = [];
      payload.meal_types = [];
      STATUS_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
      EXPIRY_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
    }

    onSubmit(payload);
  };

  /**
   * update various filter options
   * @param {*} param0
   */
  const onCheckboxChange = ({ target: { checked, name } }) => {
    setformData({ ...formData, [name]: checked });
  };

  const customCheckbox = ({ name, label, checked }) => {
    return (
      <Form.Group className="mb-3">
        <label className="checkbox">
          <input
            type="checkbox"
            onChange={onCheckboxChange}
            name={name}
            checked={checked}
          />
          <span className="me-2" />
          {label}
        </label>
      </Form.Group>
    );
  };

  const onClearDates = () => {
    setStartEndDate({
      start_date: START_DATE,
      end_date: END_DATE,
    });
  };

  const onClearAll = () => {
    onClearDates();
    setformData({});
    onClearProducts();
    onApply(true);
  };

  const handleClear = () =>{
    onClearAll();
    setClear(true);
  }

  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

  const onClearProducts = () => {
    setSelectedMealOption([]);
    setSelectedMealTypeOption({});
  };

  useEffect(() => {
    if (filterStartEndDate && filterStartEndDate.start_date !== "" && filterStartEndDate.end_date !== "") {
      setStartEndDate(filterStartEndDate);
    } else {
      setStartEndDate(startEndDate);
    }  
    setSelectedMealOption(selectedFilterProucts);
    onApply();
  }, [])

  const createList = () => {
    setIsModal(true);
  };

  const onModalHide = () => {
    setIsModal(false);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <>
      <CustomModal show={isModal} onHide={onModalHide} />
      {isFilterShown && (
        <div className="rightcontent" style={{ width: "15%", height: "87vh" }}>
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")}
                  </div>
                </Col>
              </Row>

              <div className="rightcontent-fltr">
                <div className="mb-5">
                  <div className="fltr-heading">
                    <label>{t("Meals")}</label>
                    <button onClick={onClearProducts}>{t("Clear")}</button>
                  </div>
                    <SelectAsyncPaginate
                      dataField="meal"
                      placeholder={t("SelectMeals")}
                      onChange={onSelectOption}
                      value={selectedMealOption}
                      count={selectedMealOption.length}
                      isMulti
                      query="meals"
                      key={(mealsList ?? []).join("_")}
                      multiOptions={mealsList}
                    />
                    <label onClick={createList} className="mt-3 fw-bold">
                      {t("ManageList")}
                    </label>
                </div>

                <div className="mb-5">
                  <div className="fltr-heading">
                    <label>{t("MealType")}</label>
                  </div>
                  <SelectInput
                    dataField="meal_type"
                    placeholder={t("SelectMealType")}
                    options={MEAL_CATEGORIES.slice(1).map((m) => ({
                      ...m,
                      label: t(m.label),
                    }))}
                    onChange={onSelectOption}
                    value={
                      MEAL_CATEGORIES.find(({ id }) => id === selectedMealTypeOption?.id) ??
                      null
                    }
                  />
                  {/* <SelectAsyncPaginate
                      {...(!selectedRestaurant && {
                        datasource: dummyPOSDatasource,
                      })}
                      dataField="pos"
                      placeholder={t("Select")}
                      onChange={onSelectOption}
                      value={selectedPOSOption}
                      count={selectedPOSOption.length}
                      isMulti={true}
                      query="labos/restaurants"
                      key={([]).join("_")}
                      // multiOptions={[]}
                    /> */}
                </div>
                <div className="mb-5 mt-3">
                  <div className="fltr-heading">
                    <label>{t("Date")}</label>
                    <button onClick={onClearDates}>{t("Clear")}</button>
                  </div>
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("Start")}</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={new Date(startEndDate.start_date)}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("Start date")}
                        onChange={onDateChange("start_date")}
                        className="date-picker"
                        open={isStartDateOpen}
                        onClickOutside={setDateOpen("start_date")}
                        onClick={setDateOpen("start_date", true)}
                        // minDate={new Date()}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("start_date", !isStartDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                  <hr className="hr-separator" />
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("End")}&nbsp;&nbsp;</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={new Date(startEndDate.end_date)}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("End date")}
                        onChange={onDateChange("end_date")}
                        className="date-picker"
                        open={isEndDateOpen}
                        onClickOutside={setDateOpen("end_date")}
                        onClick={setDateOpen("end_date", true)}
                        // maxDate={maxEndDate}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("end_date", !isEndDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="mb-5">
                  <div className="fltr-heading">
                    <label>{t("Status")}</label>
                    <button onClick={onClearStatus}>{t("Clear")}</button>
                  </div>
                  {STATUS_CHECKBOXES.map((check, i) => (
                    <Fragment key={i}>
                      {customCheckbox({
                        name: check.name,
                        label: t(check.label),
                        checked: formData[check.name] || false,
                      })}
                    </Fragment>
                  ))}
                </div> */}

                {/* <div>
                  <div className="fltr-heading">
                    <label>{t("Expiry")}</label>
                    <button onClick={onExpiryStatus}>{t("Clear")}</button>
                  </div>
                  {EXPIRY_CHECKBOXES.map((check, i) => (
                    <Fragment key={i}>
                      {customCheckbox({
                        name: check.name,
                        label: t(check.label),
                        checked: formData[check.name] || false,
                      })}
                    </Fragment>
                  ))}
                </div> */}
              </div>

              <div className="action-btns" style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}>
                <div style={{
                  display: "flex"
                }}>
                  <button onClick={handleClear} className="close-btn me-3">
                    <img src={CloseIcon} alt="" />
                    {t("Clear")}
                  </button>
                  <button onClick={onApply} className="apply-btn">
                    {t("Apply")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RightSide;
