import React, { useEffect, useState } from "react";

import "./index.scss";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Addicon from "assets/images/icon/ADD_WHITE.svg";
import CreateLinkIcon from "assets/images/icon/create-link.svg"
import AddRestaurantForm from "components/addRestaurantForm";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import request from "services/request";
import ConfirmationModal from "./modal";
import LinkModal from "./linkModal";

const colourStyles = {
  placeholder: (defaultStyles) => {
    return {
        ...defaultStyles,
        color: "#6353EA",
        opacity: "60%"
      }
  },
  singleValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#6353EA",
    }
  },
  option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#F3F4FB"
        : isFocused,
        "&:hover": {
          backgroundColor: "#F3F4FB"
        },
      color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
    };
  },
  multiValueLabel: (base) => ({ ...base, color: "#6353EA", fontWeight: 600, fontSize: "15px" }),
  control: (styles) => ({
    ...styles,
    minHeight: 55, // Set the height of the input field
    height: "auto",
    backgroundColor: "#efedfd", // Set the background color
    borderRadius: 10, // Set the border radius
    padding: "5px"
  }),
};
const intialState = {first_name: "", last_name: "", email: "", phone_number: "", restaurants: [], promotion_code: "", promotion_amount: "", type: "", plan: ""}



const PRICE_OFFER_OPTIONS = [
  {
    id: "commercial_restauration",
    name: "Commercial restauration"
  },
  {
    id: "hotel",
    name: "Hotel"
  },
  {
    id: "collective_catering",
    name: "Collective catering"
  },
  {
    id: "labo",
    name: "Labo"
  }
]

function Admin() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [resturantOptions, setResturantOptions] = useState([]);
  const [formData, setFormData] = useState(intialState);
  const [name, setName] = useState("")
  const [restaurants, setResturants] = useState([]);
  const [disabled, setDisabled] = useState(true)
  const [link, setLink] = useState("")

  useEffect(async() => {
    let params = {}
    if(formData.type?.value === 'labo') {
      params = {is_labo: true}
    } else {
      params = {is_labo: false}
    }
    setFormData({...formData, restaurants: []})
    const response = await request.get("restaurants/all", params);
    setResturants(response.restaurants);
    setResturantOptions(response.restaurants);
  },[formData.type?.value])

  const showModalHandler = () => {
    setShowModal((prev) => !prev);
  }

  const PACKAGE_OPTIONS = [
    {
      isDisabled: formData?.type["value"] === 'commercial_restauration' ? false : true,
      id: "standard",
      name: "Standard",
    },
    {
      id: "premium",
      name: "Premium"
    },
  ]

  useEffect(() => {
    if(Object.keys(formData).filter(item => item !== "promotion_code" && item !== "promotion_amount").some(i => formData[i].length === 0)) {
      setDisabled(true)
    }else {
      setDisabled(false)
    }
  }, [formData])
  
  const handleInputChange = (event) => {
    const filteredOptions = restaurants?.filter((option) => {
       if(option.name.toLowerCase().includes(event.toLowerCase())) {
        return true
       }
       return false
    })
    setResturantOptions(filteredOptions);
  }

  const stateHandler = (event) => {
    setFormData((prevState) => ({ ...prevState, restaurants: [...prevState.restaurants, {label: event.name, value: event.id}] }));
  }

  const generateLinkHandler = async(restaurantsData) => {
    // auth generate-registration-link 
    const payload = {
      ...formData,
      restaurants: restaurantsData.filter(i => i.checked).map((item) => item.value),
      plan: formData.plan.value,
      type: formData.type.value,
      promotion_code: formData.promotion_code || null,
      promotion_amount: formData.promotion_amount || null,
    }
    const res = await request.post("/auth/generate-registration-link", payload);
    const link = await res.json() 
    setLink(link.token)
  }

  const resetState = () => {
    setFormData(intialState)
  }

  const showFormHandler = (state) => {
    setShowForm(state)
  }

  const setSelectedMyRestaurants = (value) => {
    setFormData((prevState) => ({ ...prevState, restaurants : value }));
  }

  const setSelectedPackage = (value) => {
    let selectedPackage = `restauration_collective_${value.id}`
    switch(formData.type["value"]) {
      case "restauration_collective":
        selectedPackage = `restauration_collective_${value.id}`
        break;
      case "hotel":
        selectedPackage = `hotel_${value.id}`
        break;
      case "collective_catering":
        selectedPackage = `collective_catering_${value.id}`
        break;
      default:
    }
    setFormData((prevState) => ({ ...prevState, plan: {value: selectedPackage, id: selectedPackage, label: value.name} }));
  }

  const setSelectedPlan = (value) => {
    setFormData((prevState) => ({ ...prevState, plan: {value: value.id, id: value.id, label: value.name} }));
  }

  const setSelectedPriceOffer = (value) => {
    setFormData((prevState) => ({ ...prevState, type: value, plan: "" }));
  }

  return (
    <div style={{display: "flex", alignItems: "end", width: "100%", flexDirection: "column", overflowY: "scroll"}}>
      {showModal && <ConfirmationModal onHide={showModalHandler} stateHandler={showFormHandler} state={formData} getLink={generateLinkHandler} show={showModal}/>}
      {link && <LinkModal link={link} name={name} onHide={() => setLink("")} show={true} userEmail={formData.email}/>}
      <Card style={{ width: "100%", padding: "40px", height: "max-content", margin: "20px auto" }}>
        <h1 style={{fontWeight: "bold"}}>{t("RegistrationForm")}</h1>
        <div className="pt-5" style={{width: "100% !important", margin: "0px"}}>
          <Form style={{width: "100%"}}>
            <Form.Group className="mb-5 flex justify-between">
              <Row>
                <Col lg={6} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("FirstName")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    placeholder={t("FirstName")}
                    onChange={(event) => {
                      setFormData({ ...formData, first_name: event.target.value })
                      setName(event.target.value)
                    }
                    }
                    value={formData.first_name}
                  />
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("LastName")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    placeholder={t("LastName")}
                    required
                    onChange={(event) =>
                      setFormData({ ...formData, last_name: event.target.value })
                    }
                    value={formData.last_name}
                  />
                </Col>
              </Row>
              <Row className="mt-5 flex justify-between">
                <Col lg={6} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("Email")}
                  </span>
                  <Form.Control
                    type="email"
                    className="inputField"
                    placeholder={t("Email")}
                    onChange={(event) =>
                      setFormData({ ...formData, email: event.target.value })
                    }
                    value={formData.email}
                  />
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("PhoneNumber")}
                  </span>
                  <Form.Control
                    type="tel"
                    className="inputField"
                    placeholder={t("PhoneNumber")}
                    required
                    onChange={(event) =>
                      setFormData({ ...formData, phone_number: event.target.value })
                    }
                    value={formData.phone_number}
                  />
                </Col>
              </Row>
              <Row className="mt-5 flex justify-between">
                <Col lg={6} className="p-0">
                <Form.Group className="mb-3" controlId="restaurantName">
                    <span className="add-restaurants-input-title ps-0">
                      {t("PriceOffer")}
                    </span>
                    <Select
                        styles={colourStyles}
                        onChange={setSelectedPriceOffer}
                        components={makeAnimated()}
                        options={PRICE_OFFER_OPTIONS?.map((r) => ({
                          value: r.id,
                          label: r.name,
                        }))}
                        isSearchable={false}
                        value={formData.type}
                        placeholder={t("SelectPriceOffer")}
                    />
                  </Form.Group>
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="p-0">
                  <Form.Group className="mb-3" controlId="restaurantName">
                    <span className="add-restaurants-input-title ps-0">
                      {t("Package")}
                    </span>
                    <Select
                        styles={colourStyles}
                        onChange={setSelectedPlan}
                        onInputChange={handleInputChange}
                        components={makeAnimated()}
                        options={PACKAGE_OPTIONS?.map((r) => ({
                          ...r,
                          value: r.id,
                          label: r.name,
                        }))}
                        isSearchable={false}
                        value={formData.plan}
                        placeholder={t("SelectPackage")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-5 flex justify-between">
                <Col lg={6} className="p-0">
                  <Form.Group className="mb-3" controlId="restaurantName">
                    <span className="add-restaurants-input-title ps-0">
                      {t("RestaurantName")}
                    </span>
                    <Select
                        styles={colourStyles}
                        onChange={setSelectedMyRestaurants}
                        onInputChange={handleInputChange}
                        components={makeAnimated()}
                        options={resturantOptions?.map((r) => ({
                          value: r.id,
                          label: r.name,
                        }))}
                        isMulti
                        name="resturants"
                        value={formData.restaurants}
                        className="selectInput"
                        isSearchable
                        placeholder={t("SelectRestaurants")}
                        closeMenuOnSelect={false}
                    />
                  </Form.Group>
                </Col>
                <Col lg={{ span: 3, offset: 1 }} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("PromotionCode")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    required
                    placeholder={t("PromotionCode")}
                    onChange={(event) =>
                      setFormData({ ...formData, promotion_code: event.target.value })
                    }
                    value={formData.promotion_code}
                  />
                </Col>
                <Col style={{marginLeft: "35px", width: "13.66667%", boxSizing: "border-box"}} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("PromotionAmount")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    placeholder={t("PromotionAmount")}
                    required
                    onChange={(event) =>
                      setFormData({ ...formData, promotion_amount: event.target.value })
                    }
                    value={formData.promotion_amount}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </div>
      </Card>
      {!showForm && <div style={{ display: "flex",width: "max-content",gap: "10px", justifyContent: "space-evenly", marginTop: "20px" }}>
        <Button onClick={() => setShowForm(true)} variant="primary add-btn-container add-restaurant-btn-admin">
          <img src={Addicon} className="add-btn-icon-btn" alt="..." />
          {t("AddNewRestaurant")}
        </Button>
        <Button disabled={disabled} variant="primary add-btn-container" onClick={showModalHandler}>
          <img src={CreateLinkIcon} className="add-btn-icon" alt="..." />
          {t("CreateLink")}
        </Button>
      </div>}
      {showForm && <AddRestaurantForm stateHandler={stateHandler} restaurantData={{}} showFormHandler={showFormHandler} />}
    </div>
  );
}

export default Admin;
