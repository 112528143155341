import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { set } from "lodash";

import request from "services/request";
import CustomTable from "components/customTable";
import SortFilter from "components/customTable/sortFilter";
import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE, ITEMS_PER_PAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { cloneDeep } from "common/utils.ts";

import OrderDetailsModal from "./OrderDetailsModal";
import ReportDetailsModal from "./ReportDetailsModal";
import { getDummyOrders } from "../utils";
import providersData from "../../../data/providers.json"
import Addicon from "assets/images/icon/ADD_WHITE.svg";

const getDeleiveryStatusTextColor = (text) => {
  let str = "text-";
  if (text === "Delivered") {
    str += "delivered";
  } else if (text === "Not Delivered") {
    str += "not-delivered";
  }
  return str;
};

let isMountLoaded = false;

function MyOrders() {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [orderDetailsModalShow, setOrderDetailsModalShow] = useState(false);
  const [reportDetailsModalShow, setReportDetailsModalShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [providers, setProviders] = useState([]);
  const [changedOrders, setChangedOrders] = useState([]);

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants } =
    useUserData();
  const history = useHistory();

  useEffect(() => {
    isMountLoaded = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableColumns = [
    {
      dataField: "order_number",
      caption: t("Orders"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    {
      dataField: "estimated_delivery_date",
      caption: t("OrderDate"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => {
        return <span style={{ backgroundColor: "white", padding: "3px 5px", boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.25)", borderRadius: "4px"}}>
          {it?.received_date  ? it.received_date  : it.estimated_delivery_date}
        </span>
      }
    },
    {
      dataField: "total_price",
      caption: t("TotalCost"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "provider.name",
      caption: t("Provider"),
      className: "text-center",
      headerClassName: "text-center",
      type: "number",
    },
    {
      dataField: "delievry_status",
      caption: t("DelievryStatus"),
      className: "text-center",
      headerClassName: "text-center",
      type: "dropdown",
      options: [
        { label: t("Delivered"), id: "delivered" },
        { label: t("Not Delivered"), id: "not_delivered" },
      ],
      elem: (text) => (
        <span className={`${getDeleiveryStatusTextColor(text)}`}>
          {t(text)}
        </span>
      ),
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
      type: "edit",
    },
    {
      dataField: "report",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
      type: "edit",
    },
  ];

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setProviders(providersData);
      setCurrentItems(getDummyOrders().purchase_orders);
      setOriginalItems(getDummyOrders().purchase_orders);
      setPageCount(getDummyOrders().total_pages);
      setCurrentPage(getDummyOrders().page - 1);
      return;
    }
    if (selectedRestaurantId !== "") {
      getProvidersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, isRestaurantLoaded]);

  const getProvidersData = async () => {
    setLoading(true);
    try {
      const result = await request.get(
        `providers?restaurant_id=${selectedRestaurantId}`
      );
      let _provider = [{ name: "All Providers" }, ...result.providers];
      setProviders(_provider);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isMountLoaded || selectedRestaurantId === "") {
      let updatedItems = [...originalItems];
      // Filter by provider
      if (selectedProvider) {
        const providerName = selectedProvider.name.toLowerCase();
        updatedItems = updatedItems.filter(
          (item) => item.provider.name.toLowerCase() === providerName
        );
      }
  
      // Filter by search term
      if (fd.search) {
        updatedItems = updatedItems.filter((item) =>
          item.order_number.toLowerCase().includes(fd.search.toLowerCase())
        );
      }
  
      // Sort by specified field
      if (fd.sortby) {
        updatedItems.sort((a, b) => {
          const aValue = a[fd.sortby]?.toString().toLowerCase();
          const bValue = b[fd.sortby]?.toString().toLowerCase();
  
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
          return 0;
        });
      }
  
      setCurrentItems(updatedItems);
      return;
    }
    fetchAndSetOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fd.search,
    fd.sortby,
    fd.orderBy,
    selectedRestaurantId,
    selectedProvider,
    originalItems
  ]);

  const fetchAndSetOrder = async () => {
    try {
      const result = await getOrders();
      result.purchase_orders.forEach((value) => {
        value.delievry_status = value.is_delivered
          ? "Delivered"
          : "Not Delivered";
      });
      setCurrentItems(result.purchase_orders);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const getOrders = (page = 1) => {
    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
      ...(selectedProvider && { provider: selectedProvider.name }),
    };
    return request.get(`purchase-orders`, payload);
  };

  const onRowSelectChanged = (col, rowData) => (ev) => {
    const result = col.options.find((o) => o.id === ev);
    if (!result) {
      return;
    }
    const mapping = {
      delivered: "Delivered",
      not_delivered: "Not Delivered",
    };
    const newFilteredData = cloneDeep(currentItems);
    const isExist = newFilteredData.find((f) => f.id === rowData.id);
    if (isExist) {
      set(isExist, col.dataField, mapping[result.id]);
      isExist.state = "update";
      setCurrentItems(newFilteredData);

      let isUpdated = changedOrders.find((c) => c.id === rowData.id);
      if (isUpdated) {
        let _changedOrders = [...changedOrders];
        _changedOrders.every((value) => {
          if (value.id === rowData.id) {
            value.is_delivered = mapping[result.id] === "Delivered";
            return false;
          }
          return true;
        });
        setChangedOrders(_changedOrders);
      } else {
        let _data = {
          id: rowData.id,
          is_delivered: mapping[result.id] === "Delivered",
        };
        setChangedOrders([...changedOrders, _data]);
      }
    }
  };

  const onSearchChange = (ev) => {
    setfd({ ...fd, search: ev.target.value });
  };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((f) => f.id)] : []));
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      const newSelectedProducts = JSON.parse(JSON.stringify(selectedRows));
      if (checked) {
        setSelectedRows(() => [...newSelectedProducts, it.id]);
      } else {
        setSelectedRows(() => newSelectedProducts.filter((p) => p !== it.id));
      }
    };

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      const result = await getOrders(event.selected + 1);
      result.purchase_orders.forEach((value) => {
        value.delievry_status = value.is_delivered
          ? "Delivered"
          : "Not Delivered";
      });
      setCurrentItems(() => result.purchase_orders);
      setPageCount(result.total_pages);
      setCurrentPage(event.selected);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleProviderSelect = (eventKey, event) => {
    let _provider = JSON.parse(eventKey);
    if (_provider.name === "All Providers") {
      setSelectedProvider(null);
    } else {
      setSelectedProvider(_provider);
    }
  };

  const onUpdateRow = (orderData) => {
    setSelectedOrder(orderData);
    setOrderDetailsModalShow(true);
  };

  const onReportRow = (orderData) => {
    setSelectedOrder(orderData);
    setReportDetailsModalShow(true);
  };

  const onUpdateDelieveryStatus = async (purchase_statuses, statusFor) => {
    try {
      setLoading(true);
      const payload = {
        status_for: statusFor,
        purchase_statuses: purchase_statuses,
      };
      const result = await request.patch(
        `/purchase-orders/update-delivery-status`,
        payload
      );
      const data = await result.clone().json();
      if (result.ok) {
        if (currentPage < 1) {
          fetchAndSetOrder();
        } else {
          handlePageClick({ selected: currentPage });
        }
        setOrderDetailsModalShow(false);
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="my-orders">
      <Row>
        <Col lg={{ span: 4 }}>
          <p className="title-text">{t("MyOrders")}</p>
          <p className="sub-title-text">{t("OrdersList")}</p>
        </Col>
      </Row>

      <Row>
        {/* <Col lg={{ span: 3 }}>
          <Button
            variant="primary add-restaurant-btn"
            onClick={() => {
              history.push("/stock");
            }}
          >
            <img style={{ marginRight: "5px" }} src={Addicon} alt="" className="add-restaurant-btn-icon" />
            {t("AddOrders")}
          </Button>
        </Col> */}
        <Col className="sort-container d-flex justify-content-end">
          <input
            type="search"
            className="search-input me-3"
            placeholder={t("Search")}
            onChange={onSearchChange}
          />
          <Dropdown onSelect={handleProviderSelect}>
            <Dropdown.Toggle
              variant="link"
              className="btn btn-white dropdown-toggle btn-icon provider-icon provider-dropdown"
            >
              {selectedProvider ? selectedProvider.name : "All Providers"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {providers.map((p, i) => (
                <Dropdown.Item key={i} eventKey={JSON.stringify(p)}>
                  {p.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <SortFilter
            cols={tableColumns.slice(0, 3)}
            fd={fd}
            setfd={setfd}
            rootClassName="sort-filter ps-3"
          />
        </Col>
      </Row>

      <Row className="mt-3 table-container">
        <CustomTable
          columns={tableColumns}
          data={currentItems}
          selectChange={selectChange}
          selectedProducts={selectedRows}
          selectAllProducts={selectAllRows}
          onRowSelectChanged={onRowSelectChanged}
          onUpdateRow={onUpdateRow}
          onReportRow={onReportRow}
          tableName="my-orders"
        />

        <Row>
          <div className="d-flex justify-content-end mt-2">
            <ReactPaginate
              nextLabel={`${t("Next")}   >`}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={`<   ${t("Back")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="primary save-button mt-2"
              onClick={() => onUpdateDelieveryStatus(changedOrders, "orders")}
            >
              {t("SaveChanges")}
            </Button>
          </Col>
        </Row>
      </Row>

      {orderDetailsModalShow ? (
        <OrderDetailsModal
          show={orderDetailsModalShow}
          onHide={() => setOrderDetailsModalShow(false)}
          orderDetails={selectedOrder}
          onUpdateDelieveryStatus={onUpdateDelieveryStatus}
        />
      ) : null}

      {reportDetailsModalShow ? (
        <ReportDetailsModal
          show={reportDetailsModalShow}
          onHide={() => setReportDetailsModalShow(false)}
          orderDetails={selectedOrder}
          onUpdateDelieveryStatus={onUpdateDelieveryStatus}
        />
      ) : null}
    </div>
  );
}

export default MyOrders;
