import React, { useState, useEffect } from "react";

import {
  Modal,
  Button,
  Col,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";


import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";

import SaveImage from "assets/images/saveImage.png";
import moment from "moment";


function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title form-control">{caption}</span>
    </>
  );
}

function DateConfirmModal({
  onHide,
  show,
  submitStock,
  setDateValue
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState({ allergens: [], details: [], date: "" });
  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  const onChange = (type) => (date) => {
      setFd({...fd, date: date})
    };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="order-sent-labo"
      backdropClassName="add-ingredient-backdrop"
    >
     <div className="order-sent-modal-content">
        <h1 className="order-sent-modal-title">{t("MODIFICATIONS VALIDATED !")}</h1>
        <p className="order-sent-modal-text" style={{ width: "100%", padding: "0px", paddingBottom: "20px", paddingTop: "20px", margin: "0px"}}>
          {t("Please select the date in which you are planning to prepare updated orders")}
        </p>
        <Col style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "auto", paddingBottom: "20px"}} className="date-time">
          <span className="add-restaurants-input-title ps-0">
            {t("Date")}
          </span>
          <div className="d-flex datepicker-wrapper">
            <DatePicker
              className="date-picker form-control custom-input"
              dateFormat="dd MMM yyyy"
              placeholderText={t("SelectDate")}
              selected={fd.date}
              onChange={onChange("startDate")}
            />
          </div>
         </Col>
        <div className="order-sent-modal-button-container">
          <Button onClick={() => {
              setDateValue(fd.date);
              submitStock(moment(new Date(fd.date)).format("YYYY-MM-DD"))
              onHide()
          }} className="order-sent-modal-button">
            {t("Finish")}
          </Button>
        </div>
     </div>
    </Modal>
  );
}

export default DateConfirmModal;
