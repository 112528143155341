import React, { useState, useEffect } from "react";
import CustomModal from "components/mealListModel";
import SelectAsyncPaginate from "components/selectAsyncPaginate";
import { Row, Col } from "react-bootstrap";
import { cloneDeep } from "common/utils.ts";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";
import { useTranslation } from "react-i18next";
import request from "services/request";

import CloseIcon from "assets/images/close.png";

function RightSide({ onApply: onSubmit, formData: fd }) {
  const { t } = useTranslation();
  const {
    filterFormData,
    setFilterFormData,
    isFilterActive,
    updateFilterButton,
    selectedFilterProucts
  } = useFilterData();

  const [mealsList, setMealsList] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [formData, setFormData] = useState(filterFormData ?? { });
  const [selectedMealOption, setSelectedMealOption] = useState(selectedFilterProucts ?? []);
  const { isFilterShown, setFilterShown, selectedRestaurantId } = useUserData();

  useEffect(() => {
    // prevSelectedRestaurantId.current = selectedRestaurantId;
    onClearAll();
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (!selectedRestaurantId) return;
    if (isModal === false) {
      const getMealsList = async () => {
        const result = await request.get("meal-lists", {
          restaurant_id: selectedRestaurantId
        });
        setMealsList(result.meal_lists);
      };
      getMealsList();
    }
  }, [isModal, selectedRestaurantId]);

  const onApply = (isInitial = false) => {
    const payload = cloneDeep({ ...formData });

    if (selectedMealOption?.length) {
      payload.meals = selectedMealOption
        .map((meal) => {
          // If meal.details exists and contains objects with meal_id, extract meal_id
          if (Array.isArray(meal?.details) && meal.details.length && meal.details.every((item) => item.meal_id)) {
            return meal.details.map((item) => item.meal_id);
          } else {
            return meal.id;
          }
        })
        .flat(); // Flatten if meal_id arrays are nested
    }

    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.meals = [];
    }

    onSubmit(payload);
  };


  const onClearAll = () => {
    onClearProducts();
    setFormData({});
    onApply(true);
  };

    const onSaveFilter = () => {
        setFilterFormData(formData); 
        updateFilterButton(true);
    }

  useEffect(() => {
    setFormData(filterFormData);
    onApply();
  }, [])

  const onClearProducts = () => {
    setSelectedMealOption([]);
  };

  const onSelectOption = (dataField) => (v) => {
    if (dataField === "meal") {
      setSelectedMealOption([...v]);
    }
  };

  const createList = () => {
    setIsModal(true);
  };

  const onModalHide = () => {
    setIsModal(false);
  };

  return (
    <>
      <CustomModal show={isModal} onHide={onModalHide} />
      {isFilterShown && (
        <div className="rightcontent">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")}
                  </div>
                </Col>
              </Row>

              <div className="rightcontent-fltr">

                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Meals")}</label>
                    <button onClick={onClearProducts}>{t("Clear")}</button>
                  </div>
                    <SelectAsyncPaginate
                      dataField="meal"
                      placeholder={t("SelectMeals")}
                      onChange={onSelectOption}
                      value={selectedMealOption}
                      count={selectedMealOption.length}
                      isMulti
                      query="meals"
                      key={(mealsList ?? []).join("_")}
                      multiOptions={mealsList}
                    />
                    <label onClick={createList} className="mt-3 fw-bold">
                      {t("ManageList")}
                    </label>
                </div>
              </div>

              <div className="action-btns" style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column"
                }}>
                  <div 
                    style={{
                      marginBottom : "7px"
                    }}
                  >
                    <button onClick={onSaveFilter} className="close-btn me-3" 
                      style={{
                        width : "100%",
                        color : isFilterActive ? "#8b8f94" : "#6353ea"
                      }}
                    >
                      {t("FixFilters")}
                    </button>
                  </div>
                  <div style={{
                    display:"flex"
                  }}>
                    <button onClick={onClearAll} className="close-btn me-3">
                      <img src={CloseIcon} alt="" />
                      {t("Clear")}
                    </button>
                    <button onClick={onApply} className="apply-btn">
                      {t("Apply")}
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RightSide;