import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useFilterData } from "contexts/FilterContextManagment";
import { useUserData } from "contexts/AuthContextManagement";

import Categories from "./Categories";
import CategoriesRightSide from "./RightSidePanel";
import Providers from "./Providers";
import Router from "../../routes/router";

import "./index.scss";

function Inventories() {
  const { t } = useTranslation();
  const location = useLocation();

  const { isFilterShown } = useUserData();
  const { setShowCategoriesList } = useFilterData();

  const [clear, setClear] = useState(false);
  const [isCategoryView, setIsCategoryView] = useState(true);
  const [formData, setFormData] = useState({
    ingredients: []
  });

  useEffect(() => {
    const currentRoute = Router.find((r) => r.path === location.pathname);
    if (currentRoute?.path === "/stock") {
      setShowCategoriesList(true);
    }
  }, [location.pathname, setShowCategoriesList]);

  const onApply = (params) => setFormData({ ...params });

  return (
    <>
      <div className={`leftcontent leftcontent-stock inventory ${isFilterShown ? "hidden-left" : ""}`}>
        <div className="divider">
          <ul className="navbtns mb-0">
            <li className={`${isCategoryView ? "active" : ""}`}>
              <button
                className={`nav-link ${isCategoryView ? "active" : ""}`}
                onClick={() => {
                  setIsCategoryView(true)
                  setShowCategoriesList(true)
                }}              >
                {t("Categories")}
              </button>
            </li>
            <li className={`${!isCategoryView ? "active" : ""}`}>
              <button
                className={`nav-link ${!isCategoryView ? "active" : ""}`}
                onClick={() => {
                  setIsCategoryView(false)
                  setShowCategoriesList(false)
                }}              >
                {t("Providers")}
              </button>
            </li>
          </ul>
        </div>
        <div className="after-divider-container">
          {isCategoryView && <Categories formData={formData} clear={clear}/>}
          {!isCategoryView && <Providers formData={formData} clear={clear}/>}
        </div>
      </div>
      <CategoriesRightSide onApply={onApply} formData={formData} setClear={(clear) => setClear(clear)}/>
    </>
  );
}

export default Inventories;