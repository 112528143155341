import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

import request from "services/request";
import CustomTable from "components/customTable";
import { DEFAULT_ERROR_MESSAGE, TIME_DURATIONS } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
// occupancyTableColumns2 as tableColumns,
import { getDummyFilteredData, timezoneFormat, parseTime, getTimezoneFormatUtc } from "common/utils";
import {
  parseData,
  getGuestsOccupancy,
  parseOccupancyData,
  sumDailyOccupancy,
  getDatesInRange,
} from "./utils";
import { generateOccupancyTableRawData } from "./data";
import leftDailyOccupancyDummy from "../../data/daily_occupancy.json";

function LeftDailyOccupancy({ formData }) {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const {
    selectedRestaurantId,
    selectedRestaurant,
    isRestaurantLoaded,
    hasRetaurants,
  } = useUserData();

  const prevFromData = useRef(formData);
  const [dailyLoading, setDailyLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [intervalTableData, setIntervalTableData] = useState([]);
  const updateLanguage = localStorage.getItem('fullsoon_i18nextLng');

  useEffect(async() => {
    if (selectedRestaurantId !== "" && typeof formData.start_date === "string" && prevFromData.current !== formData) {
      await getDailyOccupancy(formData.start_date, formData.end_date);
    }
  }, [formData, updateLanguage]);

  useEffect(()=> {
    if (isRestaurantLoaded && !hasRetaurants) {
      const occupancyByDays = getDummyFilteredData(leftDailyOccupancyDummy, formData, selectedRestaurant?.timezone);
      transformTableData(occupancyByDays);
      parseIntervalOccData(occupancyByDays);
      setDailyLoading(false);
    }
  },[formData, isRestaurantLoaded, hasRetaurants])

  const transformTableData = (dailyOccupancies) => {
    // Get selected meal types from formData
    const selectedMeals = Object.keys(formData).filter(
      (key) => TIME_DURATIONS[key] && formData[key] === true
    );

    const finalData = dailyOccupancies.map((ele) => {
    // Filter occupancy data based on selected time intervals
    const filteredOccupancy = selectedMeals.length
      ? ele?.occupancy?.filter((item) => {
          // Ensure correct field name for time
          const itemTime = moment(item.interval, "HH:mm");

          return selectedMeals.some((meal) => {
            const [startTime, endTime] = TIME_DURATIONS[meal];

            return itemTime.isBetween(moment(startTime, "HH:mm"),moment(endTime, "HH:mm"), null, "[]");
          });
        })
      : ele?.occupancy; 

      // Calculate the total actual and predicted occupancy
      const totalActualOccupancy = filteredOccupancy?.reduce(
        (sum, item) => sum + (item.actual_occupancy || 0),
        0
      );

      const totalPredictedOccupancy = filteredOccupancy?.reduce(
        (sum, item) => sum + (item.predicted_occupancy || 0),
        0
      );

      // Extract the month from the date and translate it
      const formattedDate = moment(ele.date).format("DD MMM. YYYY").toUpperCase();
      const month = moment(ele.date).format("MMMM");
      const translatedMonth = t(month);
  
      // Replace the month in the formatted date with the translated month
      const translatedDate = formattedDate.replace(month, translatedMonth);

      return {
        date: translatedDate,
        total_actual_occupancy: totalActualOccupancy,
        total_predicted_occupancy: totalPredictedOccupancy,
        ...getGuestsOccupancy(ele)
      };
    });
    setTableData(finalData);
    setIntervalTableData(finalData);
  };

  const getDailyOccupancy = async (startDate, endDate) => {
    if (selectedRestaurantId === "") {
      return;
    }
    setDailyLoading(true);
    try {
      const result = await request.get(
        "occupancy",
        {
          start_date: getTimezoneFormatUtc(startDate, endDate, selectedRestaurant?.timezone)?.start_date,
          end_date: getTimezoneFormatUtc(startDate, endDate, selectedRestaurant?.timezone)?.end_date,
          restaurant_id: selectedRestaurantId,
        },
        true,
        true,
        true
      );
      const occupancyByDays = parseData(
        result.days,
        selectedRestaurant?.timezone
      );

      transformTableData(occupancyByDays);
      parseIntervalOccData(occupancyByDays);
      setDailyLoading(false);
    } catch (error) {
      if (error?.status !== 499) {
        setError(DEFAULT_ERROR_MESSAGE);
        setLoading(false);
        setDailyLoading(false);
      }
    }
  };

  const generateDailyColumns = () => {
    const today = moment().startOf("day");

    const hideMealsColumns = [
      "breakfast", "lunch", "afternoon", "dinner"
    ].some(meal => formData[meal]);

    const getTextColor = (date) => {
      const formattedDate = moment(date, "DD MMMM YYYY").startOf("day");
      return formattedDate.isSameOrAfter(today, 'day') ? "rgb(160,160,160)" : "#000000";
    };

    return [
      {
        dataField: "date",
        caption: "Date",
        style: { width: "150px", textAlign: "center", color: "#000000", fontWeight: "700" },
        headerClassName: "text-center",
        headerStyle: {
          width: "150px",
          fontWeight: "700",
          textAlign: "center",
          fontSize: "19px"
        },
        alignment: "center"
      },
      {
        dataField: "total_actual_occupancy",
        caption: t("TOTAL") + "\n" + t("Actual"),
        className: "text-center",
        headerClassName: "text-center actual-header",
        headerStyle: {
          fontWeight: "600",
          textAlign: "center",
          fontSize: "19px",
          whiteSpace: "pre-line"
        },
        type: "customRender",
          render: (column, item) => {
            return (
              <span style={{ fontSize: "17px", fontWeight: "600" }}>
                {item?.total_actual_occupancy}
              </span>
            );
          }
      },
      {
        dataField: "total_predicted_occupancy",
        caption: t("TOTAL") + "\n" + t("Predicted"),
        className: "text-center",
        headerClassName: "text-center predicted-header",
        headerStyle: {
          fontWeight: "600",
          textAlign: "center",
          fontSize: "19px",
          whiteSpace: "pre-line"
        },
        type: "customRender",
          render: (column, item) => {
            return (
              <span style={{ fontSize: "17px", color: "#873CFC", fontWeight: "600" }}>
                {item?.total_predicted_occupancy}
              </span>
            );
          }
      },
      {
        dataField: "accuracy",
        caption: `${t("Accuracy")} (%)`,
        className: "text-center",
        headerClassName: "text-center",
        headerStyle: {
          fontWeight: "700",
          textAlign: "center",
          fontSize: "19px"
        },
        type: "customRender",
        render: (column, item) => {
          const actualOccupancy = item?.total_actual_occupancy ?? 0;
          const predictedOccupancy = item?.total_predicted_occupancy ?? 0;
          const accuracy_occupancy = (actualOccupancy !== 0 && predictedOccupancy !== 0) && (actualOccupancy - predictedOccupancy) < actualOccupancy
          ? Math.max(0, (1 - Math.abs((actualOccupancy - predictedOccupancy) / actualOccupancy)) * 100)
          : 0;

          return (
            <label className={accuracy_occupancy >= 75 ? "text-green" : accuracy_occupancy >= 65 ? "text-orange" : "text-danger"} style={{ fontSize: "17px", fontWeight: "600" }}>
              {parseFloat(accuracy_occupancy).toFixed(2)}
            </label>
          );
        },
      },
      ...(hideMealsColumns && tableData.length > 0 ? [] : [
        {
          dataField: "breakfast",
          caption: t("Breakfast"),
          className: "text-center",
          headerClassName: "text-center",
          headerStyle: {
            fontWeight: "700",
            textAlign: "center",
            fontSize: "19px"
          },
          type: "customRender",
          render: (column, item) => {
            return (
              <span style={{ fontSize: "17px", color: getTextColor(item.date), fontWeight: "600" }}>
                {item?.breakfast}
              </span>
            );
          }
        },
        {
          dataField: "lunch",
          caption: t("Lunch"),
          className: "text-center",
          headerClassName: "text-center",
          headerStyle: {
            fontWeight: "700",
            textAlign: "center",
            fontSize: "19px"
          },
          type: "customRender",
          render: (column, item) => {
            return (
              <span style={{ fontSize: "17px", color: getTextColor(item.date), fontWeight: "600" }}>
                {item?.lunch}
              </span>
            );
          }
        },
        {
          dataField: "afternoon",
          caption: t("Afternoon"),
          className: "text-center",
          headerClassName: "text-center",
          headerStyle: {
            fontWeight: "700",
            textAlign: "center",
            fontSize: "19px"
          },
          type: "customRender",
          render: (column, item) => {
            return (
              <span style={{ fontSize: "17px", color: getTextColor(item.date), fontWeight: "600" }}>
                {item?.afternoon}
              </span>
            );
          }
        },
        {
          dataField: "dinner",
          caption: t("Dinner"),
          className: "text-center",
          headerClassName: "text-center",
          headerStyle: {
            fontWeight: "700",
            textAlign: "center",
            fontSize: "19px"
          },
          type: "customRender",
          render: (column, item) => {
            return (
              <span style={{ fontSize: "17px", color: getTextColor(item.date), fontWeight: "600" }}>
                {item?.dinner}
              </span>
            );
          }
        }
      ])
    ];
  }
  
  const generateIntervalColumns = () => {
    const today = moment().startOf("day");

    const getTextColor = (date) => {
      const formattedDate = moment(date, "DD MMMM YYYY").startOf("day");
      return formattedDate.isSameOrAfter(today, "day") ? "rgb(160,160,160)" : "#000000";
    };

    if (intervalTableData.length === 0) return [];

    // Exclude "date" from columnNames
    let columnNames = Object.keys(intervalTableData[0]).filter((key) => key !== "date");

    // Extract selected services from formData
    const selectedServices = Object.keys(formData).filter(service => formData[service] && TIME_DURATIONS[service]);

    if (selectedServices.length > 0) {
        let allowedTimes = new Set();
        selectedServices.forEach(service => {
            const [start, end] = TIME_DURATIONS[service];
            columnNames.forEach(time => {
                if (time >= start && time <= end) {
                    allowedTimes.add(time);
                }
            });
        });
        // Update columnNames to only include allowed time intervals
        columnNames = [...allowedTimes];
    }
    return columnNames.map((columnName) => ({
        dataField: columnName,
        caption: columnName,
        className: "text-center",
        headerClassName: "text-center",
        headerStyle: {
            fontWeight: "700",
            textAlign: "center",
            fontSize: "19px",
        },
        type: "customRender",
        render: (column, item) => {
          return (
            <span style={{ fontSize: "17px", color: getTextColor(item.date), fontWeight: "600" }}>
              {item[columnName]}
            </span>
          );
        }
    }));
};
  
  const parseIntervalOccData = (occupancy) => {
    const { timesData } = generateOccupancyTableRawData(formData, `minute`, 30);
  
    occupancy?.forEach((ele) => {
      let occupancyData = {
        date: ele.date,
      };
  
      let occupancyItems = ele.occupancy;
  
      // Convert occupancyItems into a map for faster lookup
      const occupancyMap = {};
      occupancyItems.forEach((occData) => {
        const formattedInterval = occData.interval.slice(0, 5);
        occupancyMap[formattedInterval] = occData.occupancy;
      });
  
      // Set missing intervals to 0
      timesData.forEach((interval) => {
        const formattedInterval = interval.slice(0, 5);
        if (!occupancyMap[formattedInterval]) {
          occupancyMap[formattedInterval] = 0;
        }
      });
  
      // Combine occupancyMap with date
      occupancyData = { ...occupancyData, ...occupancyMap };
  
      setIntervalTableData((prevData) => {
        return prevData.map((data) => {
          if (moment(occupancyData.date, "YYYY-MM-DD").isSame(moment(data.date, "DD MMMM YYYY"))) {
            return {
              ...occupancyData,
              ...data
            };
          }
          return data;
        });
      });
    });
  };

  let selectedDailyColumns = generateDailyColumns();
  let selectedDailyIntervalColumns = generateIntervalColumns();

  return (
    <div className="leftcontent leftcontent-occupancy">
      <div className="main-container">
        <div className="card" style={{ height: "100%" }}>
          <div className="card-header d-flex align-items-center justify-content-between card-navbtns border-bottom">
            <h2 className="daily-occupancy-heading">{t("Daily occupancy")}</h2>
          </div>
          <div className="card-body">
            {dailyLoading ? (
              <div className="w-100 d-flex justify-content-center card-spinner-container">
                <Spinner animation="border" variant="primary" />
              </div>
              ) : (
              <div className="occupancy-wrapper">
                <div className="occupancy-flex-wrapper">
                  <div style={{ 
                      width: (formData.breakfast || formData.lunch || formData.afternoon || formData.dinner)  && tableData.length > 0 ? "47%" : "100%"
                    }}>
                    <CustomTable columns={selectedDailyColumns} data={tableData} tableName="dailyOccupancy"/>
                  </div>

                  {/* Line Divider */}
                  {(formData.breakfast || formData.lunch || formData.afternoon || formData.dinner) &&
                    tableData.length > 0 && (
                      <div className="occupancy-divider" />
                  )}

                  {(formData.breakfast || formData.lunch || formData.afternoon || formData.dinner) && tableData.length > 0 && (
                    <div className="interval-occupancy-table">
                      <CustomTable columns={selectedDailyIntervalColumns} data={intervalTableData} tableName="dailyOccupancy1" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftDailyOccupancy;