import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Form,
  Card,
  FormCheck,
  Dropdown,
} from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { Trans, useTranslation } from "react-i18next";

import Addicon from "assets/images/icon/add_purple.svg";
import { WEEKDAYS } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { getTimeRange } from "../utils";
import DeleteIcon from "assets/images/icon/DELETE.svg";
import moment from "moment";
import SelectAsyncPaginate from "components/selectAsyncPaginate";
import request from "services/request";

const Delivery_Duration = [
  { value: 0, label: "A" },
  { value: 1, label: "B" },
  { value: 2, label: "C" },
  { value: 3, label: "D" },
  { value: 4, label: "E" },
  { value: 5, label: "F" },
  { value: 6, label: "G" },
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function AddProviderModal(props) {
  const { providerData } = props;
  const { t } = useTranslation();
  const { selectedRestaurantId, setSampleRestaurantModal, isLabo, selectedRestaurant } = useUserData();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const intialState = {
    name: null,
    address: null,
    email_address: null,
    credit_email_address: null,
    contact_number: null,
    contact_name: null,
    fax: null,
    category: null,
    zip_code: null,
    siren_number: null,
    siret_number: null,
    entity_number: null,
    country: null,
    state: null,
    city: null,
    delivery_condition : {
      purchasing_time_limit : null,
      settings: [],
      periodicity: null
    },
    // delivery_frequency: {
    // //   delivery_days: [],
    // //   periodicity: null,
    // },
    // purchase_settings: {
    //   purchasing_days: [],
    //   purchasing_time: null,
    //   delivery_days_duration: null,
    //   periodicity: null,
    // },
    // automatize_orders: {
    //   is_automate: null,
    //   purchasing_days: [],
    // },
    labo_restaurant_id: null,
    min_order_value: null,
    min_order_quantity: null,
  };
  const [state, setState] = useState(intialState);

  const time_ranges = getTimeRange("00:00", "23:59", 30);

  const getLaboRestaurant = async (labo_restaurant_id) => {
    return await request.get(`restaurants/${labo_restaurant_id}`);
  }

  useEffect(async() => {
    if (providerData != null) {
      setState({...providerData, delivery_condition:  {
        purchasing_time_limit : providerData.delivery_condition?.purchasing_time_limit ?? null,
        settings: providerData.delivery_condition?.settings ?? [],
        periodicity: providerData.delivery_condition?.periodicity ?? null
      },
      labo_restaurant_id: providerData.labo_restaurant_id ? await getLaboRestaurant(providerData?.labo_restaurant_id) : null
    });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.name && state.email_address && state.address) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [state]);

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();
    if (selectedRestaurantId == "") {
      setSampleRestaurantModal(true);
      return;
    } 
    let updatedState = {...state, delivery_condition: {
      ...state.delivery_condition,
      settings: state.delivery_condition.settings.map(i => 
        ({
          ...i, 
          delivery_day: i.delivery_day.toLowerCase(),
          purchasing_day: i.purchasing_day.toLowerCase()
        })
        )}}
        let labo_restaurant_id = state?.labo_restaurant_id?.id ? state.labo_restaurant_id.id : null
        delete updatedState?.labo_restaurant
    if (providerData != null) {
      props.updateProvider({...updatedState, labo_restaurant_id});
    } else {
      props.saveProvider({...updatedState, labo_restaurant_id});
    }
  };

  const onPlaceSelect = (place) => {
    var filtered_array = place.address_components.filter(function (
      address_component
    ) {
      return address_component.types.includes("country");
    });
    //country
    var country = filtered_array.length ? filtered_array[0].long_name : null;

    //city
    let matches = place.address_components.filter((address_component) =>
      ["locality", "colloquial_area"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );

    let zipCodeMatch = place.address_components.find((address_component) =>
      address_component.types.includes("postal_code")
    );

    let stateMatch = place.address_components.find((address_component) =>
      address_component.types.includes("administrative_area_level_1")
    );

    let city = null;
    if (!matches || !matches.length) {
    } else {
      city = matches[0].short_name;
    }

    setState((prevState) => ({
      ...prevState,
      address: place.formatted_address,
      country: country,
      city: city,
      state: stateMatch?.long_name ?? null,
      zip_code: zipCodeMatch?.long_name ?? null,
    }));
  };

  const onAddProviderItem =  () => {
    const newObj =  {
      delivery_day: null,
      delivery_days_duration: 0,
      purchasing_day: null,
      is_automate: false,
    }
    setState((prevState) => {
      return {
      ...prevState,
      delivery_condition: {
        ...prevState.delivery_condition,
        settings: [...prevState.delivery_condition.settings, newObj]
      },
    }}
    )
  };

  const onTimeChange = (selectedTime) => {
    setState((prevState) => {
      return {
      ...prevState,
      delivery_condition: {
        ...prevState.delivery_condition,
        purchasing_time_limit: selectedTime
      },
    }});
  };

  const onLaboChange = () => (selectedLabo) => {
    setState((prevState) => {
      return {
      ...prevState,
      labo_restaurant_id: selectedLabo
    }});
  };

  const handleDeliveryDuration = (duration, index) => {
    setState((prevState) => {
      return {
        ...prevState,
        delivery_condition: {
          ...prevState.delivery_condition,
          settings: prevState.delivery_condition.settings.map((item, i) => {
            if (i === index) {
              // Normalize the delivery_day
              const normalizedDay =
                item.delivery_day?.charAt(0).toUpperCase() +
                item.delivery_day?.slice(1).toLowerCase();
  
              return {
                ...item,
                delivery_days_duration: duration,
                purchasing_day: moment()
                  .day(normalizedDay)
                  .subtract(duration, 'days')
                  .format('dddd'),
              };
            }
            return item;
          }),
        },
      };
    });
  };
  

  const automatizeOrdersHandler = (isChecked, index) => {
    setState((prevState) => {
      return {
      ...prevState,
      delivery_condition: {
        ...prevState.delivery_condition,
        settings: prevState.delivery_condition.settings.map((item, i) => {
          if (i === index) {
            return {...item, is_automate: isChecked.target.checked}
          }
          return item
        })
      },
    }});
  }

  const deleteIngredient = (index) => () => {
    setState((prevState) => ({
      ...prevState,
      delivery_condition: {
        ...prevState.delivery_condition,
        settings: prevState.delivery_condition.settings.filter(
          (item, i) => i !== index
        )
      },
    }))
  };


  const handleDeliveryDays = (day, index) => {
    setState((prevState) => {
      return {
      ...prevState,
      delivery_condition: {
        ...prevState.delivery_condition,
        settings: prevState.delivery_condition.settings.map((item, i) => {
          if (i === index) {
            return {...item, delivery_day: day, purchasing_day: moment().day(day).subtract(item.delivery_days_duration, 'days').format('dddd')}
          }
          return item
        })
      },
    }});
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        <Modal.Title
          className="add-restaurants-modal-title"
          id="contained-modal-title-vcenter"
        >
          {providerData ? t("Edit") : t("Add")} {t("Provider")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-providers-modal-body">
        <Container>
          <Form className="ps-0 pe-0" onSubmit={handleSubmit}>
            <Form.Group className="mb-2 flex justify-between">
              <Row>
                <Col lg={6} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("ProviderName")}
                  </span>
                  <Form.Control
                    type="text"
                    className=""
                    required
                    onChange={(event) =>
                      setState({ ...state, name: event.target.value })
                    }
                    defaultValue={providerData ? providerData.name : null}
                  />
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("ContactName")}
                  </span>
                  <Form.Control
                    type="text"
                    className=""
                    onChange={(event) =>
                      setState({ ...state, contact_name: event.target.value })
                    }
                    defaultValue={
                      providerData ? providerData.contact_name : null
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <span className="add-restaurants-input-title ps-0">
                  {t("Address")}
                </span>

                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  onPlaceSelected={(place) => {
                    onPlaceSelect(place);
                  }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  defaultValue={providerData ? providerData.address : null}
                />
              </Row>
            </Form.Group>

            {/* <Form.Group className="mb-2">
              <Row style={{display: "flex", justifyContent: "space-between"}}>
                <Col lg={4}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Country")}
                    </span>

                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({ ...state, country: event.target.value })
                      }
                      value={state.country}
                      defaultValue={providerData ? providerData.country : null}
                    />
                  </Row>
                </Col>
                <Col lg={{span: 4}}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("State")}
                    </span>

                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({ ...state, state: event.target.value })
                      }
                      value={state.state}
                      defaultValue={providerData ? providerData.state : null}
                    />
                  </Row>
                </Col>
                <Col lg={{ span: 3 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("ZIPCode")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({ ...state, zip_code: event.target.value })
                      }
                      defaultValue={providerData ? providerData.zip_code : null}
                      value={state.zip_code}
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group> */}

            <Form.Group className="mb-2">
              <Row>
                <Col lg={6}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("EmailAddress")}
                    </span>

                    <Form.Control
                      type="text"
                      className=""
                      required
                      onChange={(event) =>
                        setState({
                          ...state,
                          email_address: event.target.value,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.email_address : null
                      }
                    />
                  </Row>
                </Col>
                <Col lg={{ span: 5, offset: 1 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("CreditEmailAddress")}
                    </span>

                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          credit_email_address: event.target.value ?? null,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.credit_email_address : null
                      }
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>
            {/* <Form.Group className="mb-2">
              <Row>
                <Col lg={4}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("DeliveryDays")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                        <Dropdown className="ps-0 pe-0">
                          <Dropdown.Toggle
                            variant="button"
                            className="dropdown-toggle btn-icon restaurant-dropdown "
                          >
                            {t("SelectDays")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="type-dropdown-menu">
                            {WEEKDAYS.map((w, i) => (
                              <div class="form-check weekdays-dropdown-item">
                                <input
                                  onChange={(e) => onDaysChange(e, w)}
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={
                                    state.delivery_frequency &&
                                    state.delivery_frequency.delivery_days &&
                                    state.delivery_frequency.delivery_days
                                      .length >= 0
                                      ? state.delivery_frequency.delivery_days.indexOf(
                                          w.toLowerCase()
                                        ) > -1
                                      : false
                                  }
                                />
                                <label class="form-check-label"><Trans t={t}>{w}</Trans></label>
                              </div>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={{ offset: 1, span: 7 }}>
                  <Row class="form-check form-check-inline">
                    <span className="add-restaurants-input-title ps-0">
                      {t("Event Occurrence")}
                    </span>

                    <Col lg={{ span: 3 }} className="mt-2 ps-0">
                      <div
                        class="form-check"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            delivery_frequency: {
                              ...prevState.delivery_frequency,
                              periodicity: "weekly",
                            },
                          }))
                        }
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          checked={
                            state.delivery_frequency &&
                            state.delivery_frequency.periodicity
                              ? state.delivery_frequency.periodicity ===
                                "weekly"
                              : false
                          }
                        />
                        <label class="frequency-label-text">
                          {t("Weekly")}
                        </label>
                      </div>
                    </Col>
                    <Col lg={{ span: 3 }} className="mt-2 ps-0">
                      <div
                        class="form-check"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            delivery_frequency: {
                              ...prevState.delivery_frequency,
                              periodicity: "monthly",
                            },
                          }))
                        }
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          checked={
                            state.delivery_frequency &&
                            state.delivery_frequency.periodicity
                              ? state.delivery_frequency.periodicity ===
                                "monthly"
                              : false
                          }
                        />
                        <label class="frequency-label-text">
                          {t("Monthly")}
                        </label>
                      </div>
                    </Col>
                    <Col lg={{ span: 2 }} className="mt-2 ps-0">
                      <div
                        class="form-check"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            delivery_frequency: {
                              ...prevState.delivery_frequency,
                              periodicity: "weeks",
                            },
                          }))
                        }
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          checked={
                            state.delivery_frequency &&
                            state.delivery_frequency.periodicity
                              ? state.delivery_frequency.periodicity.includes(
                                  "weeks"
                                )
                              : false
                          }
                        />
                        <label class="frequency-label-text">{t("Every")}</label>
                      </div>
                    </Col>

                    {state.delivery_frequency &&
                    state.delivery_frequency.periodicity &&
                    state.delivery_frequency.periodicity.includes("weeks") ? (
                      <Col
                        lg={{ span: 4 }}
                        className="mt-1 ps-0 every-input-text"
                      >
                        <div>
                          <input
                            type="number"
                            class="form-input frequency-every-input"
                            onChange={(e) => {
                              setState((prevState) => ({
                                ...prevState,
                                delivery_frequency: {
                                  ...prevState.delivery_frequency,
                                  periodicity: e.target.value + " weeks",
                                },
                              }));
                            }}
                            defaultValue={
                              state.delivery_frequency &&
                              state.delivery_frequency.periodicity
                                ? state.delivery_frequency.periodicity.includes(
                                    "weeks"
                                  )
                                  ? state.delivery_frequency.periodicity.split(
                                      " "
                                    )[0]
                                  : 0
                                : 0
                            }
                          />
                          <label class="frequency-label-text ps-2">
                            {t("Weeks")}
                          </label>
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Form.Group> */}

            <Form.Group className="mb-2">
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Col lg={{ span: 4 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("PhoneNumber")}
                    </span>
                    <Form.Control
                      type="tel"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          contact_number: event.target.value,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.contact_number : null
                      }
                    />
                  </Row>
                </Col>
                <Col lg={{ span: 4 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Fax")}
                    </span>
                    <Form.Control
                      type="tel"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          fax: event.target.value,
                        })
                      }
                      defaultValue={providerData ? providerData.fax : null}
                    />
                  </Row>
                </Col>
                {/* <Col lg={{ span: 3 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Category")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          category: event.target.value,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.category : null
                      }
                    />
                  </Row>
                </Col> */}

                <Col lg={{ span: 3 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("ZIPCode")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({ ...state, zip_code: event.target.value })
                      }
                      defaultValue={providerData ? providerData.zip_code : null}
                      value={state.zip_code}
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Col lg={{ span: 4 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("SirenNumber")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          siren_number: event.target.value,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.siren_number : null
                      }
                    />
                  </Row>
                </Col>
                <Col lg={{ span: 4 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("SiretNumber")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          siret_number: event.target.value,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.siret_number : null
                      }
                    />
                  </Row>
                </Col>
                <Col lg={{ span: 3 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("EntityNumber")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          entity_number: event.target.value,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.entity_number : null
                      }
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Col lg={{ span: 5 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("MinimumOrderValue")}
                    </span>
                    <Form.Control
                      type="number"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          min_order_value: event.target.value,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.min_order_value : null
                      }
                    />
                  </Row>
                </Col>
                <Col lg={{ span: 5 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("MinimumOrderQuantity")}
                    </span>
                    <Form.Control
                      type="number"
                      className=""
                      onChange={(event) =>
                        setState({
                          ...state,
                          min_order_quantity: event.target.value,
                        })
                      }
                      defaultValue={
                        providerData ? providerData.min_order_quantity : null
                      }
                    />
                  </Row>
                </Col>
                 <Col lg={{ span: 5 }} className="ps-0" >
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Labo")}
                    </span>
                    <SelectAsyncPaginate
                      // {...(!selectedRestaurant && {
                      //   datasource: dummyDatasource,
                      // })}
                      dataField="labos"
                      placeholder={t("Select Labo")}
                      onChange={onLaboChange}
                      value={state.labo_restaurant_id}
                      query="restaurants/all"
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            {/* <Form.Group className="mb-2">
              <Row>
              <Col lg={6}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("PurchasingDays")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                        <Dropdown className="ps-0 pe-0">
                          <Dropdown.Toggle
                            variant="button"
                            className="dropdown-toggle btn-icon restaurant-dropdown "
                          >
                            {t("SelectDays")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="type-dropdown-menu">
                            {WEEKDAYS.map((t, i) => (
                              <div className="form-check weekdays-dropdown-item">
                                <input
                                  onChange={(e) => onDaysChange(e, t)}
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={
                                    state.delivery_frequency &&
                                    state.delivery_frequency.delivery_days &&
                                    state.delivery_frequency.delivery_days
                                      .length >= 0
                                      ? state.delivery_frequency.delivery_days.indexOf(
                                          t.day.toLowerCase()
                                        ) > -1
                                      : false
                                  }
                                />
                                <label class="form-check-label">{t.day}</label>
                              </div>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={{span: 5, offset: 1}}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("PurchasingTime")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                      <Dropdown className="ps-0 pe-0" onSelect={onTimeChange}>
                        <Dropdown.Toggle variant="button" id="dropdown-basic" className="dropdown-toggle btn-icon restaurant-dropdown">
                          {selectedOption ? selectedOption : t('SelectTime')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="type-dropdown-menu">
                          {time_ranges.map((option) => (
                            <Dropdown.Item class="form-check weekdays-dropdown-item" key={option} eventKey={option}>
                              {option}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group> */}
        <Card className="mb-0" style={{ marginTop: "10px"}}>
          <div className="p-4 allergens-container">
            <Form.Group style={{ marginBottom: "15px"}}>
              <Row>
                <Col lg={4}>
                <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("OrderDeadlineTime")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                      <Dropdown className="ps-0 pe-0" onSelect={onTimeChange}>
                        <Dropdown.Toggle variant="button" id="dropdown-basic" className="dropdown-toggle btn-icon restaurant-dropdown">
                          {state?.delivery_condition?.purchasing_time_limit ?? t('SelectTime')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="type-dropdown-menu">
                          {time_ranges.map((option) => (
                            <Dropdown.Item class="form-check weekdays-dropdown-item" key={option} eventKey={option}>
                              {option}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>
          {(state.delivery_condition?.settings || []).map(
            (item, i) => (
            <Form.Group className="mb-4">
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Col lg={2}>
                <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("DeliveryDays")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                        <Dropdown
                          className="ps-0 pe-0"
                          onSelect={(e) => handleDeliveryDays(e, i)}
                        >
                          <Dropdown.Toggle
                            variant="button"
                            id="dropdown-basic"
                            className="dropdown-toggle btn-icon restaurant-dropdown"
                          >
                            {item.delivery_day ? t(capitalizeFirstLetter(item.delivery_day)) : t("SelectDay")}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="type-dropdown-menu">
                            {WEEKDAYS.map((option) => (
                              <Dropdown.Item
                                class="form-check weekdays-dropdown-item"
                                key={option.id}
                                eventKey={option.day}
                              >
                                {t(option.day)}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("DeliveryTime")}
                    </span>
                    <Form.Control
                      type="text"
                      disabled
                      className=""
                      defaultValue="A"
                    />
                  </Row>
                </Col>
                <span style={{width: "max-content", paddingTop: "35px"}}>
                  {t("For")}
                </span>
                <Col lg={1}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0" style={{paddingTop: "14px"}}>
                      {/* {t("DeliveryDuration")} */}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                        <Dropdown
                          className="ps-0 pe-0"
                          onSelect={(e) => handleDeliveryDuration(e, i)}
                        >
                          <Dropdown.Toggle
                            variant="button"
                            id="dropdown-basic"
                            className="dropdown-toggle btn-icon restaurant-dropdown"
                          >
                            {t(Delivery_Duration[item.delivery_days_duration].label)}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="type-dropdown-menu">
                            {Delivery_Duration.map((option) => (
                              <Dropdown.Item
                                class="form-check weekdays-dropdown-item"
                                key={option.label}
                                eventKey={option.value}
                              >
                                {option.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={3}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("OrderDeadlineDay")}
                    </span>
                    <Col lg={{ span: 12 }} style={{padding: "0px"}}>
                    <Form.Control
                      type="text"
                      disabled
                      className=""
                      value={item.purchasing_day ?t(capitalizeFirstLetter(item.purchasing_day)) : ""}
                    />
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row class="form-check form-check-inline">
                    <span className="add-restaurants-input-title ps-0">
                      {t("AutomateOrders")}
                    </span>

                    <Col lg={{ span: 4 }} style={{width: "100%", textAlign: "center"}} className="mt-2 ps-0">
                      <FormCheck 
                        type="switch"
                        checked={item.is_automate}
                        id="custom-switch-check"
                        onChange={(e) => automatizeOrdersHandler(e, i)}
                      />
                    </Col>
                  </Row>
                </Col>
                <span className="icon-container" style={{width: "2.333333%", padding: "0px", display: "flex", alignItems: "center", paddingTop: "26px", cursor: "pointer"}}>
                  <img
                    src={DeleteIcon}
                    className="icon"
                    alt=""
                   onClick={deleteIngredient(i)}
                  />
                </span>
              </Row>
            </Form.Group>)
            )}

            <Form.Group>
              <Row style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="add-ingredient"
                onClick={onAddProviderItem}
                style={{width: "max-content",padding: "12px 20px", border: "2px solid #D9D9D9", borderRadius: "10px"}}
              >
                <img
                  src={Addicon}
                  className="add-btn-icon"
                  alt="..."
                  style={{marginRight: "10px"}}
                />
                {t("AddADeliveryCondition")}
              </Button>
              </Row>
            </Form.Group>
            </div>
          </Card>
            {/* <Form.Group className="my-4">
              <Row>
                <Col lg={5}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("DeliveryDuration")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                        <Dropdown
                          className="ps-0 pe-0"
                          onSelect={handleDeliveryDuration}
                        >
                          <Dropdown.Toggle
                            variant="button"
                            id="dropdown-basic"
                            className="dropdown-toggle btn-icon restaurant-dropdown"
                          >
                            {deliveryDuration.label}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="type-dropdown-menu">
                            {Delivery_Duration.map((option) => (
                              <Dropdown.Item
                                class="form-check weekdays-dropdown-item"
                                key={option.label}
                                eventKey={option.value}
                              >
                                {option.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={{ span: 6, offset: 1 }}>
                  <Row class="form-check form-check-inline">
                    <span className="add-restaurants-input-title ps-0">
                      {t("Event Occurrence")}
                    </span>

                    <Col lg={{ span: 4 }} className="mt-2 ps-0">
                      <div
                        class="form-check"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            delivery_frequency: {
                              ...prevState.delivery_frequency,
                              periodicity: "weekly",
                            },
                            purchase_settings: {
                              ...prevState.purchase_settings,
                              periodicity: "weekly",
                            },
                          }))
                        }
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          checked={
                            state.delivery_frequency &&
                            state.delivery_frequency.periodicity
                              ? state.delivery_frequency.periodicity ===
                                "weekly"
                              : false
                          }
                        />
                        <label class="frequency-label-text">
                          {t("Weekly")}
                        </label>
                      </div>
                    </Col>
                    <Col lg={{ span: 3 }} className="mt-2 ps-0">
                      <div
                        class="form-check"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            delivery_frequency: {
                              ...prevState.delivery_frequency,
                              periodicity: "monthly",
                            },
                            purchase_settings: {
                              ...prevState.purchase_settings,
                              periodicity: "monthly",
                            },
                          }))
                        }
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          checked={
                            state.delivery_frequency &&
                            state.delivery_frequency.periodicity
                              ? state.delivery_frequency.periodicity ===
                                "monthly"
                              : false
                          }
                        />
                        <label class="frequency-label-text">
                          {t("Monthly")}
                        </label>
                      </div>
                    </Col>
                    <Col lg={{ span: 2 }} className="mt-2 ps-0">
                      <div
                        class="form-check"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            delivery_frequency: {
                              ...prevState.delivery_frequency,
                              periodicity: "weeks",
                            },
                            purchase_settings: {
                              ...prevState.purchase_settings,
                              periodicity: "weeks",
                            },
                          }))
                        }
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          checked={
                            state.delivery_frequency &&
                            state.delivery_frequency.periodicity
                              ? state.delivery_frequency.periodicity.includes(
                                  "weeks"
                                )
                              : false
                          }
                        />
                        <label class="frequency-label-text">{t("Every")}</label>
                      </div>
                    </Col>

                    {state.delivery_frequency &&
                    state.delivery_frequency.periodicity &&
                    state.delivery_frequency.periodicity.includes("weeks") ? (
                      <Col
                        lg={{ span: 4 }}
                        className="mt-1 ps-0 every-input-text"
                      >
                        <div>
                          <input
                            type="number"
                            class="form-input frequency-every-input"
                            onChange={(e) => {
                              setState((prevState) => ({
                                ...prevState,
                                delivery_frequency: {
                                  ...prevState.delivery_frequency,
                                  periodicity: e.target.value + " weeks",
                                },
                                purchase_settings: {
                                  ...prevState.purchase_settings,
                                  periodicity: e.target.value + " weeks",
                                },
                              }));
                            }}
                            defaultValue={
                              state.delivery_frequency &&
                              state.delivery_frequency.periodicity
                                ? state.delivery_frequency.periodicity.includes(
                                    "weeks"
                                  )
                                  ? state.delivery_frequency.periodicity.split(
                                      " "
                                    )[0]
                                  : 0
                                : 0
                            }
                          />
                          <label class="frequency-label-text ps-2">
                            {t("Weeks")}
                          </label>
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Form.Group> */}
            {/* <Form.Group>
              <Row>
                <Col lg={4}>
                  <Row class="form-check form-check-inline">
                    <span className="add-restaurants-input-title ps-0">
                      {t("AutomateOrders")}
                    </span>

                    <Col lg={{ span: 4 }} className="mt-2 ps-0">
                      <div
                        class="form-check"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            automatize_orders: {
                              ...prevState.automatize_orders,
                              is_automate: true,
                            },
                          }))
                        }
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          checked={
                            state.automatize_orders &&
                            state.automatize_orders.is_automate === true
                              ? true
                              : false
                          }
                        />
                        <label class="frequency-label-text">{t("Yes")}</label>
                      </div>
                    </Col>

                    <Col lg={{ span: 4 }} className="mt-2 ps-0">
                      <div
                        class="form-check"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            automatize_orders: {
                              ...prevState.automatize_orders,
                              is_automate: false,
                              purchasing_days: [],
                            },
                          }))
                        }
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          checked={
                            state.automatize_orders &&
                            state.automatize_orders.is_automate === false
                              ? true
                              : false
                          }
                        />
                        <label class="frequency-label-text">{t("No")}</label>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {state.automatize_orders &&
                state.automatize_orders.is_automate === true ? (
                  <Col lg={{ span: 4 }}>
                    <Row>
                      <span className="add-restaurants-input-title ps-0">
                        {t("PurchasingDays")}
                      </span>
                      <Col lg={{ span: 12 }}>
                        <Row>
                          <Dropdown className="ps-0 pe-0">
                            <Dropdown.Toggle
                              variant="button"
                              className="dropdown-toggle btn-icon restaurant-dropdown "
                            >
                              {t("SelectDays")}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="type-dropdown-menu">
                              {WEEKDAYS.map((t, i) => (
                                <div class="form-check weekdays-dropdown-item">
                                  <input
                                    onChange={(e) => onPurDaysChange(e, t)}
                                    class="form-check-input"
                                    type="checkbox"
                                    checked={
                                      state.automatize_orders &&
                                      state.automatize_orders.purchasing_days &&
                                      state.automatize_orders.purchasing_days
                                        .length >= 0
                                        ? state.automatize_orders.purchasing_days.indexOf(
                                            t.day.toLowerCase()
                                          ) > -1
                                        : false
                                    }
                                  />
                                  <label class="form-check-label">
                                    {t.day}
                                  </label>
                                </div>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
            </Form.Group> */}

            <Row>
              <Modal.Footer className="add-restaurants-modal-footer pe-0">
                <Button
                  type="submit"
                  className="add-restaurant-confirm-btn"
                  disabled={isSubmitDisabled}
                >
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default AddProviderModal;
