import { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

import request from "services/request";
import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";

import CustomTable from "components/customTable";
import QuantityContainer from "components/quantityBtnContainer";

import { DEFAULT_ERROR_MESSAGE, ITEMS_PER_PAGE } from "common/constants";
import { cloneDeep } from "common/utils.ts";
import { set } from "lodash";

import "./index.scss";

function LeftSide({formData}) {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [fd, setFd] = useState({ order_by: "DESC", search: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [orderDetailsModalShow, setOrderDetailsModalShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const tableRef = useRef(null);

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [ finishedMealLoading, setFinishedMealLoading ] = useState(false);
  const [ finishedData, setFinishedData ] = useState([]);
  const {  selectedRestaurantId} = useUserData();

  const tableColumns = [
    {
      caption: t("Name"),
      dataField: "name",
      type: "string",
      active: false,
    },
    {
      dataField: "unit",
      caption: t("Unit"),
      className: "fw-bold",
      style: { width: "150px", color: "#873CFC", fontWeight: "bold", textAlign: "center" },
      headerStyle: { width: "150px", textAlign: "center" },
      type: "string"
    },
    {
      dataField: "reference_number",
      caption: t("Reference Number"),
      className: "fw-bold",
      style: { width: "150px", color: "#873CFC", fontWeight: "bold", textAlign: "center" },
      headerStyle: { width: "150px", textAlign: "center" },
      type: "string",
    },
    {
      caption: t("Stock"),
      className: "text-center",
      headerClassName: "text-center",
      style: { width: "100px", fontWeight: "bold" },
      headerStyle: { width: "100px" },
      type: "customRender",
      render: (col, it) => {
        return <QuantityContainer currentValue={it?.stock?.stock ? it?.stock?.stock : 0} setCurrentValue={(_, value) => {
          updateOrderItemQty(it, value)
        }} />
    }
    },
    {
      dataField: "unit_price",
      caption: t("Unit Price"),
      className: "fw-bold",
      style: { width: "150px", color: "#873CFC", fontWeight: "bold", textAlign: "center" },
      headerStyle: { width: "150px", textAlign: "center" },
      type: "string"
    }
  ];

  const updateOrderItemQty = async(meal, value) => {
    const payload = {
      stocks: [
        {
          meal_id: meal.id,
          stock: value,
          difference: value - meal?.stock?.theoretical_stock || 0,
          restaurant_id: meal.restaurant_id,
        }
      ],
    };
    const resultQuery = await request.post(`/meal-stocks`, payload);
    let updatedFinishedData = finishedData?.map(item => item.id === meal.id ? {...item, stock: {...item.stock, stock: value}} : item)
    setFinishedData(updatedFinishedData)
  }

  const getFinishedMealData = async (page = 1, is_initial = false) => {
    try {
      setFinishedMealLoading(true);
      const result = await request.get(
        `meal-stocks`,
        {
          restaurant_id : selectedRestaurantId,
          limit: ITEMS_PER_PAGE,
          meals: formData?.meals ?? [],
          page,
        },
        true,
        true,
        true
      );
      setFinishedData(result?.meal_stock);
      setCurrentPage(is_initial ? 0 : result.page);
      setPageCount(result.total_pages);
      setFinishedMealLoading(false);
    } catch (error) {
      if (error?.status !== 499) {
        setFinishedMealLoading(false);
      }
    }
  };

  useEffect(async() => {
    if(selectedRestaurantId){
      await getFinishedMealData(undefined, true);
    }
  },[formData?.meals, selectedRestaurantId])

  const onRowSelectChanged = (col, rowData) => (ev) => {
    const result = col.options.find((o) => o.id === ev);
    if (!result) {
      return;
    }
    const newFilteredData = cloneDeep(currentItems);
    const isExist = newFilteredData.find((f) => f.id === rowData.id);
    if (isExist) {
      set(isExist, col.dataField, result.value);
      isExist.state = "update";
      setCurrentItems(newFilteredData);
    }
  };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...finishedData] : []));
  };

  const selectChange = (it) => ({ target: { checked } }) => {
      const newSelectedProducts = JSON.parse(JSON.stringify(selectedRows));
      if (checked) {
        setSelectedRows(() => [...newSelectedProducts, it]);
      } else {
        setSelectedRows(() => newSelectedProducts.filter((p) => p.id !== it.id));
      }
    };

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      await getFinishedMealData(event.selected + 1);
      setCurrentPage(event.selected);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onUpdateRow = (orderData) => {
    setSelectedOrder(orderData);
    setOrderDetailsModalShow(true);
  };

  const onReportRow = (orderData) => {
    setSelectedOrder(orderData);
  };

  return (
    <div className="my-orders">
      <Row className="mt-3 table-container">
        <CustomTable
          columns={tableColumns}
          data={finishedData}
          ref={tableRef}
          tableName="labo-order"
          pageName="labo"
          selectChange={selectChange}
          selectedProducts={selectedRows.map((p) => p.id)}
          selectAllProducts={selectAllRows}
          onRowSelectChanged={onRowSelectChanged}
          onUpdateRow={onUpdateRow}
          onReportRow={onReportRow}
        />

        <Row>
          <div className="d-flex justify-content-end mt-2">
            <ReactPaginate
              nextLabel={`${t("Next")}   >`}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={`<   ${t("Back")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
      </Row>
    </div>
  );
}

export default LeftSide;