import React from "react";
import { Dropdown } from "react-bootstrap";

import SortIcon from "assets/images/icon/SORT.svg";
import ArrowDownIcon from "assets/images/icon/arrow_down.svg";
import ArrowUpIcon from "assets/images/icon/arrow_up.svg";
import { useTranslation } from "react-i18next";

function SortFilter({ cols, fd, setFd, rootClassName }) {
  const { t } = useTranslation();
  const handleSelect = (val) => {
    // getSortedItems(val)
    setFd({ ...fd, sortby: val });
  };

  return (
    <>
      <Dropdown onSelect={handleSelect} className={rootClassName}>
        <Dropdown.Toggle
          variant="link"
          className="btn btn-white dropdown-toggle btn-icon"
        >
          {fd.order_by !== "ASC" && (
            <img
              src={ArrowDownIcon}
              alt=""
              className="me-0"
              style={{ width: "11px", paddingTop: "3px" }}
            />
          )}
          {fd.order_by === "ASC" && (
            <img
              src={ArrowUpIcon}
              alt=""
              className="me-0"
              style={{ width: "11px" }}
            />
          )}
          <img
            src={SortIcon}
            alt=""
            style={{
              ...(fd.order_by === "ASC" && {
                transform: "rotate(180deg)",
              }),
            }}
          />
          {fd?.sortby
            ? cols.find((c) => c.dataField === fd.sortby)?.caption
            : t("SortBy")}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{width: "min-content"}}>
          {cols
            .filter((c) => (c.dataField !== "action" && c.dataField !== "action_status"))
            .map((d, i) => (
              <Dropdown.Item key={i} eventKey={d.dataField}>
                {d.caption}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default SortFilter;

export const sortTableData = (fd, tableColumns, tableData) => {
  if (!fd.sortby) {
    return tableData;
  }
  const sortByCol = tableColumns.find((c) => c.dataField === fd.sortby);
  let sortedData = [];
  if (fd.order_by === "DESC") {
    if (sortByCol.type === "string") {
      sortedData = tableData.sort((a, b) =>
        a[fd.sortby].localeCompare(b[fd.sortby])
      );
    } else if (sortByCol.type === "number") {
      sortedData = tableData.sort((a, b) => b[fd.sortby] - a[fd.sortby]);
    }
  } else {
    if (sortByCol.type === "string") {
      sortedData = tableData.sort((a, b) =>
        b[fd.sortby].localeCompare(a[fd.sortby])
      );
    } else if (sortByCol.type === "number") {
      sortedData = tableData.sort((a, b) => a[fd.sortby] - b[fd.sortby]);
    }
  }

  return sortedData;
};
