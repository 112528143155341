import React, { useRef } from "react";
import { Modal, Button, Row, Col, Container, Card } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/en-gb";
import moment from "moment";
import { useUserData } from "contexts/AuthContextManagement";

import FullSoonLogo from "assets/images/fullsoon_logo_1.svg";
import "./index.scss";
import { useLoading } from "contexts/LoadingContextManagement";

function Text({ caption, style }) {
  const customStyle = { ...style, border: "none", width: "auto", paddingLeft: "0" };
  return (
    <span style={customStyle} className="add-restaurants-input form-control bg-transparent">
      {caption}
    </span>
  );
}

function ConsolidateModal({ onHide, show, formData, selectedRows }) {
  const { t, i18n } = useTranslation();
  const { selectedRestaurant } = useUserData();
  const { setLoading } = useLoading();
  const currentLanguage = i18n.language;
  moment.locale(currentLanguage);
  const selectedIngredient = selectedRows[0] || {};

  // Process and group orders
  const filteredOrders = selectedRows.filter((order) => order.status === "1");
  const groupedOrders = filteredOrders.reduce((acc, order) => {
    const { order_number, sent_date, restaurant, labo_items } = order;
    const total_price_excl_tax = labo_items?.reduce(
      (total, item) => total + (item.product_quantity * item.unit_price + (item.unit_tax || 0)),
      0
    );
    const total_tax = labo_items?.reduce((total, item) => total + (item.unit_tax || 0), 0);
    const orderData = {
      order_number,
      sent_date,
      total_price_excl_tax,
      total_tax,
      total_price: total_price_excl_tax + total_tax,
    };

    const restaurantName = restaurant.name;
    if (!acc[restaurantName]) {
      acc[restaurantName] = [];
    }
    acc[restaurantName].push(orderData);
    return acc;
  }, {});

  let totalPrice = filteredOrders.reduce((sum, row) => {
    const laboItemsTotal = row.labo_items.reduce(
      (itemSum, item) => itemSum + (item.unit_price * item.product_quantity + (item.unit_tax || 0)),
      0
    );
    return sum + laboItemsTotal;
  }, 0)?.toFixed(2);

  const totalTax = filteredOrders.reduce((sum, row) => {
    const laboItemsTotal = row.labo_items.reduce(
      (itemSum, item) => itemSum + (item.unit_tax || 0),
      0
    );
    return sum + laboItemsTotal;
  }, 0)?.toFixed(2);

  totalPrice = ((parseFloat(totalPrice) + parseFloat(totalTax)) || 0).toFixed(2);

  // Reference for PDF content
  const pdfRef = useRef(null);

  // Function to generate PDF with proper margins and automatic page breaks
  const generatePdf = () => {
    setLoading(true);
    const input = pdfRef.current;
    // Increase scale for better resolution
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // Create a jsPDF instance in portrait with A4 page size
      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Define margins (in mm)
      const margin = 10;
      const usableWidth = pageWidth - margin * 2;
      const usableHeight = pageHeight - margin * 2;

      // Calculate image dimensions to fit usableWidth
      const imgProps = pdf.getImageProperties(imgData);
      const ratio = usableWidth / imgProps.width;
      const imgHeight = imgProps.height * ratio;

      // Starting position (top margin)
      let heightLeft = imgHeight;
      let position = margin;

      // Add first page
      pdf.addImage(imgData, "PNG", margin, position, usableWidth, imgHeight);
      heightLeft -= usableHeight;

      // Add additional pages if necessary
      while (heightLeft > 0) {
        pdf.addPage();
        // Calculate the position offset for the next page
        position = -(imgHeight - heightLeft) + margin;
        pdf.addImage(imgData, "PNG", margin, position, usableWidth, imgHeight);
        heightLeft -= usableHeight;
      }
      pdf.save("labo-order.pdf");
      setLoading(false);
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="order-pdf-modal"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton />
      <Modal.Body>
        <Container style={{ maxWidth: "90%" }}>
          <div className="modal-header">
            <h3>{t("Good delivery consolidation for headquarters")}</h3>
            <Button onClick={generatePdf} variant="primary">
              {t("Download")}
            </Button>
          </div>
          {/* PDF Content Container */}
          <Card
            ref={pdfRef}
            className="modal-card-container"
            style={{
              padding: "20px",
              margin: 0,
              minHeight: "auto",
              // Extra bottom padding to ensure proper margin on the last page
              paddingBottom: "40px",
            }}
          >
            <div className="logo-image" style={{ paddingLeft: "22px" }}>
              <img src={FullSoonLogo} alt="logo" />
            </div>
            <div className="modal-sub-container">
              <div className="left-side" style={{ width: "100%" }}>
                <div className="date-container" style={{ paddingLeft: "22px" }}>
                  <div style={{ fontSize: "21px", fontWeight: "bold" }}>
                    {t("Delivery note")}{" "}
                    <span style={{ color: "#8232FF" }}>{selectedRestaurant?.name}</span>
                  </div>
                  <div style={{ fontSize: "21px", fontWeight: "bold" }}>
                    {t("From")}{" "}
                    <span style={{ color: "#8232FF" }}>
                      {moment(formData?.start_date, "YYYY-MM-DD").format("DD MMMM YYYY")}
                    </span>{" "}
                    {t("to")}{" "}
                    <span style={{ color: "#8232FF" }}>
                      {moment(formData?.end_date, "YYYY-MM-DD").format("DD MMMM YYYY")}
                    </span>
                  </div>
                </div>
                <Card className="labo-container-modal">
                  <Card.Header style={{ padding: "0px" }}>
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold" }}>
                      {t("Labo Details")}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input">{t("Name")}</span>
                        <Text
                          style={{
                            width: "100%",
                            whiteSpace: "initial",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.name}
                        />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Phone")}</span>
                        <Text
                          style={{
                            width: "100%",
                            whiteSpace: "initial",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.phone_number}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Mail")}</span>
                        <Text
                          style={{
                            width: "100%",
                            whiteSpace: "initial",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.email}
                        />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Address")}</span>
                        <Text
                          style={{
                            width: "100%",
                            whiteSpace: "initial",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.address}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("SIRET Number")}</span>
                        <Text
                          style={{
                            width: "100%",
                            whiteSpace: "initial",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.siret_number}
                        />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Contact name")}</span>
                        <Text
                          style={{
                            width: "100%",
                            whiteSpace: "initial",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.contact_name}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Card className="delivery-container">
              <Card.Header className="delivery-header">
                <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold" }}>
                  {t("Delivery details")}
                </div>
              </Card.Header>
              <Card.Body className="delivery-body">
                {Object.keys(groupedOrders).map((orderItem, index) => (
                  <Row key={index}>
                    <Row>
                      <Col lg={6} className="p-0">
                        <p className="consolidate-details-sub-header">
                          {orderItem} -{" "}
                          <span className="fullsoon-color">
                            {(groupedOrders[orderItem].reduce((acc, cur) => acc + cur.total_price, 0))?.toFixed(2)}€ {t("incl.tax")}
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg={12}>
                        <div className="p-4 allergens-container">
                          <div className="row custom-table h-100">
                            <div className="col-lg-12 h-100 p-0">
                              <div className="tablescroll">
                                <table className="table bg-transparent">
                                  <thead>
                                    <tr>
                                      <td style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}>{t("Reference")}</td>
                                      <td className="text-center" style={{ width: "50%", fontSize: "13px", fontWeight: "bold" }}>{t("Delivery date")}</td>
                                      <td className="text-center" style={{ width: "30%", fontSize: "13px", fontWeight: "bold" }}>
                                        {t("Price")} {t(" (EXCL. VAT)")}
                                      </td>
                                      <td className="text-center" style={{ width: "20%", fontSize: "13px", fontWeight: "bold" }}>{t("VAT")}</td>
                                      <td className="text-center" style={{ width: "25%", fontSize: "13px", fontWeight: "bold", color: "#8232FF" }}>
                                        {t("Total Price")} {t(" (INCL. VAT)")}
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupedOrders[orderItem]?.map((laboItem, idx) => (
                                      // Removed flex classes to preserve native table layout
                                      <tr key={idx}>
                                        <td style={{ height: "50px" }}>
                                          <Text
                                            style={{ width: "100%", display: "inline-block", whiteSpace: "initial" }}
                                            caption={laboItem?.order_number}
                                          />
                                        </td>
                                        <td className="text-center" style={{ width: "200px" }}>
                                          <Text
                                            style={{ overflow: "hidden" }}
                                            caption={laboItem?.sent_date ? laboItem.sent_date : laboItem.shipping_date}
                                          />
                                        </td>
                                        <td style={{ width: "100px" }} className="text-center">
                                          <Text caption={laboItem.total_price_excl_tax?.toFixed(2)} />
                                        </td>
                                        <td style={{ width: "100px" }} className="text-center">
                                          <Text caption={laboItem.total_tax?.toFixed(2)} />
                                        </td>
                                        <td style={{ width: "100px" }} className="text-center">
                                          <Text caption={laboItem.total_price?.toFixed(2)} />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                ))}
              </Card.Body>
              <Card.Footer
                style={{
                  height: "100px",
                  width: "100%",
                  backgroundColor: "#8232FF",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  color: "white",
                  paddingRight: "50px",
                }}
              >
                <span>
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    <sub>
                      {t("Total")} <sub>{t(" INCL. VAT")}</sub>
                    </sub>
                    <span style={{ fontSize: "32px", fontWeight: "bold", marginLeft: "5px" }}>
                      {totalPrice}
                    </span>
                    <sub>€</sub>
                  </span>
                </span>
              </Card.Footer>
            </Card>
          </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ConsolidateModal;
