import React, { useMemo, useState } from "react";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import useResponsiveFontSize from "components/useResponsiveFontSize";
import { useHistory } from "react-router-dom";
import request from "services/request";
import { useUserData } from "contexts/AuthContextManagement";
import storage from "services/storage";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        supportedCountries: ["SEPA"],
        style: {
          base: {
            fontSize,
            color: "#424770",
            letterSpacing: "0.025em",
            width: "200px",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
      [fontSize]
    );
  
    return options;
  };

const IbanForm = () => {
  const { t, i18n } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [error, setError] = useState(null)
  const { setSubscription, username, email } = useUserData();
  const history = useHistory()

  const handleSubmit = async event => {
    try{
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const paymentMethod = await stripe.createPaymentMethod({
      type: "sepa_debit",
      sepa_debit: elements.getElement(IbanElement),
      billing_details: {
        name: username,
        email: email
      }
    });
    const { id } = paymentMethod.paymentMethod;
    const subscription = JSON.parse(storage.getItem("subscription"))

      const payload = {
        plan: subscription?.plan,
        type: subscription?.type, 
        payment_method: id,
        promo_code: storage.getItem("promo_code") || null,
        language: i18n.language
      }
      const response = await request.post("/subscription", payload);
      const res = await response.json();
      if(!response.ok || response.status === 400) {
        setError(t(res.msg))
        setTimeout(() => {
          setError(null)
        },10000)
        return
      }

        let {paymentIntent, error} = await stripe.confirmSepaDebitPayment(res.client_secret, {
          payment_method: {
            sepa_debit: elements.getElement(IbanElement),
            billing_details: {
              name: username,
              email: email
            }
          }
        });

        if (error) {
          toast.error(error.message)
          setTimeout(() => {
            history.push("/signin")
            window.location.reload()
          },3000)
          return
        } 
        if(res?.status === 'active' && paymentIntent?.status === 'succeeded') {
          setSubscription({status: res.status});
          storage.setItem("subscription",  JSON.stringify({status: res.status, plan: res?.plan}))
          history.push("/forecast");
          window.location.reload()
          return
        }else if(res?.status === 'incomplete') {
          // if the initial payment attempt fails
          toast.info(`Your subscription is under Processing`)
          setTimeout(() => {
          history.push("/signin")
          window.location.reload()
          },3000)
        }else if (res?.status === 'incomplete_expired') {
          // If the first invoice is not paid within 23 hours
          toast.info(`Your subscription is under Processing`)
          setTimeout(() => {
          history.push("/signin")
          window.location.reload()
          },3000)
        }else if(res?.status === 'past_due') {
          // when payment is required but cannot be paid (due to failed payment or awaiting additional user actions)
          toast.info(`Your subscription is under Processing`)
          setTimeout(() => {
          history.push("/signin")
          window.location.reload()
          },3000)
        }else if(res?.status === 'canceled' || res?.status === 'unpaid') {
          // when payment is required but cannot be paid (due to failed payment or awaiting additional user actions)
          toast.info(`Your subscription is Canceled, Please try to pay again`)
          setTimeout(() => {
          history.push("/signin")
          window.location.reload()
          },3000)
        }

      if (response.error) {
        return;
      }
    } catch (error) {
      console.log(error)
    }

  };

  return (
    <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column", width: "500px"}}>
      {/* <Form.Group className="mb-3">
            <Form.Label className="input-title">{t("Name")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("Name")}
              required
              name="name"
            />
          </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="input-title">{t("Email")}</Form.Label>
        <Form.Control
          type="email"
          placeholder={t("Email")}
          required
          name="email"
        />
      </Form.Group> */}
      <label>
        IBAN
        <IbanElement
          options={options}
          onReady={() => {
            console.log("IbanElement [ready]");
          }}
          onChange={event => {
            console.log("IbanElement [change]", event);
          }}
          onBlur={() => {
            console.log("IbanElement [blur]");
          }}
          onFocus={() => {
            console.log("IbanElement [focus]");
          }}
        />
      </label>
      <Button disabled={!stripe} type='submit' style={{display: "block", margin: "50px auto", height: "56px", fontSize: "16px", fontWeight: "700", margin: "auto", width: "100%", borderRadius: "10px", cursor: "pointer"}} variant="primary signup-btn">
            {t("RegisterPaymentMethod")}
      </Button>
      {error && <p style={{color: "red", textAlign: "center", paddingTop: "20px"}}>{error}</p>}
    </form>
  );
};

export default IbanForm;
