import CloseIcon from "assets/images/close.png";

function Sleave({ width, isOpen, onClose, content, row, hideCloseBtn = false }) {
  return (
    <tr className="bg-transparent">
      <td colSpan={width} className="bg-transparent" ref={(e) => e?.style.setProperty('padding', '0px', 'important')}>
        <div className={`sleave-container ${isOpen ?"sleave-container-open":"sleave-container-close"}`}>
        <table className="table" style={{marginBottom: '0px'}}>
          <tbody>
          {row}
          </tbody>
        </table>
        <div
          className={`sleave-body ${
            isOpen ? "sleave-body-open" : "sleave-body-close"
          }`}
        >
          {content}
         {!hideCloseBtn && <img
              src={CloseIcon}
              className="close-sleave-icon"
              onClick={onClose}
            />}
        </div>
        </div>
      </td>
    </tr>
  );
}

export default Sleave;
