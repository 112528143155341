import React, { useState, useEffect, useReducer, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap"

import request from "services/request";
import CustomTable from "components/customTable";
import SortFilter from "components/customTable/sortFilter";
import DeleteModal from "views/commonViews/DeleteModal";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import { cloneDeep } from "common/utils.ts";
import { useLoading } from "contexts/LoadingContextManagement";

import CustomModal from "./modal";

import reducer, { ACTION_TYPES, initialState } from "./reducer";
import {
  DEBOUNCE_DELAY,
  DEFAULT_ERROR_MESSAGE,
  ITEMS_PER_PAGE,
  MEAL_CATEGORIES,
} from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { getMealsDummyData, getProvidersDummy, mealsKeys, laboMealsKeys, requiredMealsKeys } from "../utils";

import Addicon from "assets/images/icon/ADD_WHITE.svg";
import Trashicon from "assets/images/icon/filter.svg";
import EyeIcon from "assets/images/icon/eye.svg";

import "./index.scss";
import PriceHistory from "../myIngredients/PriceHistory";
import ImportModule from "../common/ImportIngredients";

let isMountLoaded = false;

function MyMeals() {
  const { t } = useTranslation();
  const { currencySymbol } = useCurrencySymbol();
  const { setLoading, setError } = useLoading();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [modalShow, setModalShow] = useState(false);
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [importModalShow, setImportModalShow] = useState(false);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  const [isMyMeals, setIsMyMeals] = useState(true);
  const [mealLoading, setMealLoading] = useState(true);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sleaveState, setSleaveState] = useState({
    index: -1,
    isOpen: true,
  });
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants, isLabo } = useUserData();

  const tableColumns = [
    {
      dataField: "name",
      caption: t("Meals"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    {
      dataField: "servings",
      caption: t("Servings"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "cost_per_person",
      caption: t("Cost") + (currencySymbol ? ` (${currencySymbol})` : ''),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        return (
          <span>
            {it?.total_cost || 0}
          </span>
        );
      }
    },
    // show the price per person column if it is POS meals
    ...(
      !isMyMeals ? [
        {
          dataField: "price_per_person",
          caption: t("Price") + (currencySymbol ? ` (${currencySymbol})` : ''),
          className: "text-center",
          headerClassName: "text-center",
          type: "number",
        },
      ] : []
    ),
    // show the margin column if it is POS meals
    ...(
      !isMyMeals ? [
        {
          dataField: "margin_per_person",
          caption: t("Margin") + (currencySymbol ? ` (${currencySymbol})` : ''),
          className: "text-center",
          headerClassName: "text-center",
          type: "customRender",
          render: (_, it) => (
            <span>
              {it.margin_per_person || 0}
            </span>
          ),
        },
      ] : []
    ),
    // Conditionally add the threshold_for_foodcost column only for demo account
      ...(isRestaurantLoaded && !hasRetaurants ? [
        {
            dataField: "threshold_for_foodcost",
            caption: t("Foodcost%"),
            className: "text-center",
            headerClassName: "text-center",
            type: "customRender",
            render: (_, it) => {
                let percentage = 0;
                let threshold = 0;

                if (it.type !== "drinks") {
                    percentage = (it.total_cost / it.price_per_person) * 100;
                    threshold = 25;
                } else {
                    percentage = (it.total_cost / it.price_per_person) * 100;
                    threshold = 15;
                }
                if (!isFinite(percentage)) {
                    return null;
                }
                const color = percentage < threshold ? "green" : "red";
                const displayPercentage = percentage !== 0 ? `${percentage.toFixed(2)}%` : '';

                return (
                    <span style={{ color }}>
                        {displayPercentage}
                    </span>
                );
            }
        }
      ] : []), 
    {
      dataField: "type",
      caption: t("Category"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => (
        <span>
          {t(
            MEAL_CATEGORIES.find(({ id }) => id === it?.type)?.label
          )}
        </span>
      ),
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      type: "customRender",
      render: (_, it) => (
        <img
          src={EyeIcon}
          className="cursor-pointer"
          alt="..."
          onClick={showRow(it)}
        />
      ),
    },
  ];

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      const result = await getMeals(event.selected + 1);
      setCurrentItems(() => result.meals);
      setPageCount(result.total_pages);
      setCurrentPage(event.selected);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    getInitialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, isRestaurantLoaded, hasRetaurants, isMyMeals]);

  useEffect(() => {
    if (!isMountLoaded) {
      return;
    }
    fetchAndSetMeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fd.search, fd.sortby, fd.orderBy]);

  const fetchAndSetMeals = async () => {
    try {
      const result = await getMeals(currentPage+1);
      dispatch({ type: ACTION_TYPES.MULTI, meals: result.meals });
      setCurrentItems(() => result.meals);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
      setMealLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const getMeals = (page = 1) => {
    if (isRestaurantLoaded && !hasRetaurants) {
      return {
        meals: getMealsDummyData(),
        total_pages: 1,
        page: 0,
      };
    }

    let payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      is_external: isMyMeals ? false : true,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
    };
  
    return request.get(`meals`, payload);
  };

  const getAllergens = () => request.get("allergens");

  const getProviders = () => {
    if (isRestaurantLoaded && !hasRetaurants) {
      return getProvidersDummy();
    }
    return request.get(`providers?restaurant_id=${selectedRestaurantId}`);
  };

  const getInitialData = async () => {
    setLoading(true);
    setMealLoading(true);
    try {
      const [meals, allergens, providers] = await Promise.all([
        getMeals(),
        getAllergens(),
        getProviders(),
      ]);
      dispatch({
        type: ACTION_TYPES.MULTI,
        payload: {
          meals: meals.meals,
          allergens: allergens.allergens,
          providers: providers.providers,
        },
      });
      setCurrentItems(() => meals.meals);
      setPageCount(meals.total_pages);
      isMountLoaded = true;
      setLoading(false);
      setMealLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const showRow = (row) => () => {
    setSelectedMeal({ ...row, selling_price_per_person: row.price_per_person });
    setModalShow(true);
  };

  const onSearchChange = debounce((ev) => {
    setfd({ ...fd, search: ev.target.value });
  }, DEBOUNCE_DELAY);

  const onRowAdded = () => {
    fetchAndSetMeals();
    setSelectedMeal(null);
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      const newSelectedRows = cloneDeep(selectedRows);
      if (checked) {
        setSelectedRows(() => [...newSelectedRows, it.id]);
      } else {
        setSelectedRows(() => newSelectedRows.filter((p) => p !== it.id));
      }
    };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((f) => f.id)] : []));
  };

  const deleteRows = async () => {
    try {
      const result = await request.delete(`/meals`, { meal_ids: selectedRows });
      if (result.status === 200) {
        setSelectedRows([]);
        setCurrentItems(() =>
          currentItems.filter((i) => !selectedRows.includes(i.id))
        );
      }
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onModalHide = () => {
    setfd({ orderBy: "desc", search: "" });
    setSelectedMeal(null);
    setModalShow(false);
  };

  const handleDoubleClick = (index, isOpen) => {
    if(isOpen) {
      setSleaveState(p => ({...p, isOpen: false}))
      setTimeout(() => {
        setSleaveState(p => ({...p, index: -1}))
      }, 300)
    } else {
      setSleaveState(p => ({...p, index, isOpen: true }))
    }
  }

  const processedCurrentItems = useMemo(() => {
    const newCurrentItems = [...currentItems];
    if(sleaveState.index !== -1) {
      // newCurrentItems.splice(sleaveState.index+1, 0, {prediction:{}, isOpen: sleaveState.isOpen})
      newCurrentItems[sleaveState.index] = {...newCurrentItems[sleaveState.index], prediction: { isOpen: sleaveState.isOpen }};
    }
    return newCurrentItems;
  }, [currentItems, sleaveState])

  return (
    <div className="my-ingredients">

      {modalShow && (
        <CustomModal
          show={modalShow}
          onHide={onModalHide}
          onRowAdded={onRowAdded}
          setSelectedMeal={setSelectedMeal}
          state={{ ...state, selectedMeal: selectedMeal || state.selectedMeal }}
        />
      )}

      {!modalShow && (
        <>
          <ImportModule title="AddMultipleMeals" modalName='Meal' requestPath='/meals/create-in-bulk' show={importModalShow} options={isLabo ? laboMealsKeys : mealsKeys} requiredOptions={requiredMealsKeys} onHide={setImportModalShow} providers={state.providers} onRowAdded={onRowAdded} />
          
          <DeleteModal
            show={deleteModalShow}
            onHide={() => setDeleteModelShow(false)}
            onPositiveClicked={() => {
              setDeleteModelShow(false);
              deleteRows();
            }}
            modalData={{
              title: t("Delete meals"),
              description: t(
                "Are you sure you want to delete meals ? You cannot undo this action."
              ),
              positiveBtnTitle: t("Yes, delete meals"),
            }}
          />

          <Row>
          <div>
            <ul className="navbtns mb-0">
              <li className={`${isMyMeals ? "active" : ""}`}>
                <button
                  className={`nav-link ${isMyMeals ? "active" : ""}`}
                  onClick={() => setIsMyMeals(true)}
                >
                  {t("MyRecipes")}
                </button>
              </li>
              <li className={`${!isMyMeals ? "active" : ""}`}>
                <button
                  className={`nav-link ${!isMyMeals ? "active" : ""}`}
                  onClick={() => setIsMyMeals(false)}
                >
                  {t("ProductsFromPOS")}
                </button>
              </li>
            </ul>
            </div>
          </Row>

          <Row>
            <div className="d-flex row" style={{marginTop: "50px"}}>
              {isMyMeals && <Col xs="auto">
                <Button
                  variant="primary add-btn-container"
                  onClick={() => setModalShow(true)}
                >
                  <img src={Addicon} className="add-btn-icon" alt="..." />
                  {t("AddMeal")}
                </Button>
              </Col>}
              {isMyMeals && <Col xs="auto">
                <Button
                  variant="primary add-btn-container"
                  onClick={() => setImportModalShow(true)}
                >
                  <img src={Addicon} className="add-btn-icon" alt="..." />
                  {t("AddMultipleMeals")}
                </Button>
              </Col>}
              <Col className="sort-container d-flex justify-content-end">
                {Boolean(selectedRows.length) && (
                  <img
                    src={Trashicon}
                    onClick={() => setDeleteModelShow(true)}
                    className="me-3"
                    alt="..."
                  />
                )}
                <input
                  type="search"
                  className="search-input me-3"
                  placeholder={t("Search")}
                  onChange={onSearchChange}
                />
                <SortFilter
                  cols={tableColumns}
                  fd={fd}
                  setfd={setfd}
                  rootClassName="sort-filter"
                />
              </Col>
            </div>
          </Row>

          {mealLoading ? (
            <div
              className="row"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
            ) : (
            <Row className="mt-3 table-container">
              <CustomTable
                columns={tableColumns}
                data={processedCurrentItems}
                editRow={showRow}
                selectChange={selectChange}
                selectedProducts={selectedRows}
                selectAllProducts={selectAllRows}
                pageName="meals"
              />

              <Row>
                <div className="meal-pagination">
                  <ReactPaginate
                    nextLabel={`${t("Next")}   >`}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={`<   ${t("Back")}`}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Row>
            </Row>
          )}               
        </>
      )}
    </div>
  );
}

export default MyMeals;
