import React, { useState } from "react";
import moment from "moment";

import LeftSide from "./leftSide";
import RightSide from "./rightSide";
import "../index.scss";

function ProductionPlanning() {
  const [clear, setClear] = useState(false);
  const [formData, setformData] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().add(14, 'days').format("YYYY-MM-DD"),
    meals: [],
    meal_types: {},
  });
  
  const onApply = (params) => {
    setformData({ ...params });
  };

  return (
    <div className="productionPlanning-container" style={{ display: "flex" }}>
      <LeftSide formData={formData} clear={clear}/>
      <RightSide onApply={onApply} formData={formData} setClear={(clear) => setClear(clear)} />
    </div>
  );
}

export default ProductionPlanning;
