import React, { useState, useEffect, Fragment, useRef } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Row, Col, Dropdown } from "react-bootstrap";
import { cloneDeep } from "common/utils.ts";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";
import { useTranslation } from "react-i18next";

import { TIMES_CHECKBOXES } from "common/constants";

import CloseIcon from "assets/images/close.png";
import CalendarIcon from "assets/images/calendar_datepicker.png";
import CompareStatusIcon from "assets/images/icon/compare-status.svg";

import "react-datepicker/dist/react-datepicker.css";

const SERVICE_CHECKBOXES = [
  { name: "breakfast", label: "Breakfast" },
  { name: "lunch", label: "Lunch" },
  { name: "afternoon", label: "Afternoon" },
  { name: "dinner", label: "Dinner" }
];

const START_DATE = new Date(new Date().setDate(new Date().getDate() - 3));
const END_DATE = new Date(new Date().setDate(new Date().getDate() + 3));

function RightDailyOccupancy({ onApply: onSubmit, formData : fd }) {
  const { t } = useTranslation();
  const {
    filterStartEndDate,
    setFilterStartEndDate,
    filterFormData,
    setFilterFormData,
    isFilterActive,
    updateFilterButton
  } = useFilterData();

  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [formData, setFormData] = useState(filterFormData ?? { });
  const [startEndDate, setStartEndDate] = useState({
    start_date: filterStartEndDate && filterStartEndDate.start_date !== "" ? filterStartEndDate.start_date : START_DATE,
    end_date: filterStartEndDate && filterStartEndDate.end_date !== "" ? filterStartEndDate.end_date : END_DATE,
  });
  const { isFilterShown, setFilterShown, selectedRestaurantId } = useUserData();

  useEffect(() => {
    onClearAll();
  }, [selectedRestaurantId]);

  const onDateChange = (name) => (e) => {
    setStartEndDate({ ...startEndDate, [name]: e });
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  const onApply = (isInitial = false) => {
    const payload = cloneDeep({ ...formData, ...startEndDate });
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (payload.start_date) {
      payload.start_date = getFormattedDate(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = getFormattedDate(payload.end_date);
    }

    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.start_date = getFormattedDate(START_DATE);
      payload.end_date = getFormattedDate(END_DATE);
      TIMES_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
    }

    onSubmit(payload);
  };

  const onCheckboxChange = ({ target: { checked, name } }) => {
    setFormData({ ...formData, [name]: checked });
  };

  const customCheckbox = ({ name, label, checked }) => {
    return (
      <Form.Group className="mb-3">
        <label className="checkbox">
          <input
            type="checkbox"
            onChange={onCheckboxChange}
            name={name}
            checked={checked}
          />
          <span className="me-2" />
          {label}
        </label>
      </Form.Group>
    );
  };

  const onClearTimes = () => {
    const newFormData = cloneDeep(formData);
    TIMES_CHECKBOXES.map((x) => x.name).forEach((t) => delete newFormData[t]);
    setFormData({ ...newFormData });
  };

  const onClearDates = () => {
    setStartEndDate({
      start_date: START_DATE,
      end_date: END_DATE,
    });
  };

  const onClearAll = () => {
    onClearDates();
    setFormData({});
    onApply(true);
  };

  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

    const onSaveFilter = () => {
      setFilterStartEndDate(startEndDate)
      setFilterFormData(formData); 
      updateFilterButton(true);
    }

  useEffect(() => {
    if (filterStartEndDate && filterStartEndDate.start_date !== "" && filterStartEndDate.end_date !== "") {
      setStartEndDate(filterStartEndDate);
    } else {
      setStartEndDate(startEndDate);
    }   
    setFormData(filterFormData);
    onApply();
  }, [])

  return (
    isFilterShown && (
      <div className="rightcontent">
        <div className="card">
          <div className="card-body">
            <Row>
              <Col lg={4}>
                <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
              </Col>
              <Col lg={8}>
                <div
                  className="hide-filter"
                  onClick={() => setFilterShown(false)}
                >
                  {t("HideFilters")}
                </div>
              </Col>
            </Row>

            <div className="rightcontent-fltr">
              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("Date")}</label>
                  <button onClick={onClearDates}>{t("Clear")}</button>
                </div>
                <div className="d-flex datepicker-wrapper">
                  <span>{t("Start")}</span>
                  <div className="datepicker-wrapper-img">
                    <DatePicker
                      selected={startEndDate.start_date}
                      dateFormat="dd MMM yyyy"
                      placeholderText={t("Start date")}
                      onChange={onDateChange("start_date")}
                      className="date-picker"
                      open={isStartDateOpen}
                      onClickOutside={setDateOpen("start_date")}
                      onClick={setDateOpen("start_date", true)}
                    />
                    <img
                      src={CalendarIcon}
                      onClick={setDateOpen("start_date", !isStartDateOpen)}
                      alt=""
                    />
                  </div>
                </div>
                <hr className="hr-separator" />
                <div className="d-flex datepicker-wrapper">
                  <span>{t("End")}&nbsp;&nbsp;</span>
                  <div className="datepicker-wrapper-img">
                    <DatePicker
                      selected={startEndDate.end_date}
                      dateFormat="dd MMM yyyy"
                      placeholderText={t("End date")}
                      onChange={onDateChange("end_date")}
                      className="date-picker"
                      open={isEndDateOpen}
                      onClickOutside={setDateOpen("end_date")}
                      onClick={setDateOpen("end_date", true)}
                    />
                    <img
                      src={CalendarIcon}
                      onClick={setDateOpen("end_date", !isEndDateOpen)}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              {/* <div>
                <div className="fltr-heading">
                  <label>{t("Time")}</label>
                  <button onClick={onClearTimes}>{t("Clear")}</button>
                </div>
                {TIMES_CHECKBOXES.map((check, i) => {
                  const [labelName, ...rest] = check.label.split(" ");
                  const label = t(labelName) + " " + rest.join(" ");
                  return (
                    <Fragment key={i}>
                      {customCheckbox({
                        name: check.name,
                        label,
                        checked: formData[check.name] || false,
                      })}
                    </Fragment>
                  );
                })}
              </div> */}

              <div className="filter-time">
                <Dropdown align="end">
                  <Dropdown.Toggle
                    style={{ width: "100%" }}
                    variant="button"
                    className="btn btn-white dropdown-toggle btn-icon"
                  >
                    <span style={{ color: "rgb(99, 83, 234)", fontWeight: "bold" }}>
                      <img src={CompareStatusIcon} alt="order-status" />
                      {t("Service")}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ padding : "7px 17px" }}>
                    {SERVICE_CHECKBOXES.map(({ name, label }) => (
                      <Form.Group className="mb-2 d-flex align-items-center" key={name}>
                        <Form.Check
                          type="checkbox"
                          label={t(label)}
                          name={name}
                          checked={formData[name]}
                          onChange={onCheckboxChange}
                        />
                      </Form.Group>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="action-btns" style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
              }}>
                <div 
                  style={{
                    marginBottom : "7px"
                  }}
                >
                  <button onClick={onSaveFilter} className="close-btn me-3" 
                    style={{
                      width : "100%",
                      color : isFilterActive ? "#8b8f94" : "#6353ea"
                    }}
                  >
                    {t("FixFilters")}
                  </button>
                </div>
                <div style={{
                  display:"flex"
                }}>
                  <button onClick={onClearAll} className="close-btn me-3">
                    <img src={CloseIcon} alt="" />
                    {t("Clear")}
                  </button>
                  <button onClick={onApply} className="apply-btn">
                    {t("Apply")}
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  );
}

export default RightDailyOccupancy;