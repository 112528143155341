import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import CustomModal from "components/mealListModel";
import SelectAsyncPaginate from "components/selectAsyncPaginate";
import { Row, Col } from "react-bootstrap";
import { cloneDeep } from "common/utils.ts";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";
import { useTranslation } from "react-i18next";
import request from "services/request";

import CloseIcon from "assets/images/close.png";
import CalendarIcon from "assets/images/calendar_datepicker.png";

import "react-datepicker/dist/react-datepicker.css";

const START_DATE = new Date(new Date().setDate(new Date().getDate() - 8));
const END_DATE = new Date(new Date().setDate(new Date().getDate() - 1));

function RightSide({ onApply: onSubmit, formData: fd }) {
  const { t } = useTranslation();
  const {
    filterStartEndDate,
    setFilterStartEndDate,
    filterFormData,
    setFilterFormData,
    isFilterActive,
    updateFilterButton,
    selectedFilterProucts
  } = useFilterData();

  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [mealsList, setMealsList] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [formData, setFormData] = useState(filterFormData ?? { });
  const [startEndDate, setStartEndDate] = useState({
    start_date: filterStartEndDate && filterStartEndDate.start_date !== "" ? filterStartEndDate.start_date : START_DATE,
    end_date: filterStartEndDate && filterStartEndDate.end_date !== "" ? filterStartEndDate.end_date : END_DATE,
  });
  const [selectedMealOption, setSelectedMealOption] = useState(selectedFilterProucts ?? []);
  const { isFilterShown, setFilterShown, selectedRestaurantId } = useUserData();


  // useEffect(() => {
  //   onApply();
  // }, []);

  useEffect(() => {
    // prevSelectedRestaurantId.current = selectedRestaurantId;
    onClearAll();
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (!selectedRestaurantId) return;
    if (isModal === false) {
      const getMealsList = async () => {
        const result = await request.get("meal-lists", {
          restaurant_id: selectedRestaurantId
        });
        setMealsList(result.meal_lists);
      };
      getMealsList();
    }
  }, [isModal, selectedRestaurantId]);

  const onDateChange = (name) => (e) => {
    setStartEndDate({ ...startEndDate, [name]: e });
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  const onApply = (isInitial = false) => {
    const payload = cloneDeep({ ...formData, ...startEndDate });
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (payload.start_date) {
      payload.start_date = getFormattedDate(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = getFormattedDate(payload.end_date);
    }

    if (selectedMealOption?.length) {
      payload.meals = selectedMealOption
        .map((meal) => {
          // If meal.details exists and contains objects with meal_id, extract meal_id
          if (Array.isArray(meal?.details) && meal.details.length && meal.details.every((item) => item.meal_id)) {
            return meal.details.map((item) => item?.meal_id);
          } else {
            return meal.id;
          }
        })
        .flat(); // Flatten if meal_id arrays are nested
    }

    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.meals = [];
      payload.start_date = getFormattedDate(START_DATE);
      payload.end_date = getFormattedDate(END_DATE);
    }

    onSubmit(payload);
  };

  const onClearDates = () => {
    setStartEndDate({
      start_date: START_DATE,
      end_date: END_DATE,
    });
  };

  const onClearAll = () => {
    onClearDates();
    onClearProducts();
    setFormData({});
    onApply(true);
  };

  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

    const onSaveFilter = () => {
      setFilterStartEndDate(startEndDate)
      setFilterFormData(formData); 
      updateFilterButton(true);
    }

  useEffect(() => {
    if (filterStartEndDate && filterStartEndDate.start_date !== "" && filterStartEndDate.end_date !== "") {
      setStartEndDate(filterStartEndDate);
    } else {
      setStartEndDate(startEndDate);
    }   
    setFormData(filterFormData);
    onApply();
  }, [])

  const onClearProducts = () => {
    setSelectedMealOption([]);
  };

  const onSelectOption = (dataField) => (v) => {
    if (dataField === "meal") {
      setSelectedMealOption([...v]);
    }
  };

  const createList = () => {
    setIsModal(true);
  };

  const onModalHide = () => {
    setIsModal(false);
  };

  return (
    <>
      <CustomModal show={isModal} onHide={onModalHide} />
      {isFilterShown && (
        <div className="rightcontent">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")}
                  </div>
                </Col>
              </Row>

              <div className="rightcontent-fltr">

                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Meals")}</label>
                    <button onClick={onClearProducts}>{t("Clear")}</button>
                  </div>
                    <SelectAsyncPaginate
                      dataField="meal"
                      placeholder={t("SelectMeals")}
                      onChange={onSelectOption}
                      value={selectedMealOption}
                      count={selectedMealOption.length}
                      isMulti
                      key={(mealsList ?? []).join("_")}
                      multiOptions={mealsList}
                    />
                    <label onClick={createList} className="mt-3 fw-bold">
                      {t("ManageList")}
                    </label>
                </div>

                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Date")}</label>
                    <button onClick={onClearDates}>{t("Clear")}</button>
                  </div>
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("Start")}</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={startEndDate.start_date}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("Start date")}
                        onChange={onDateChange("start_date")}
                        className="date-picker"
                        open={isStartDateOpen}
                        onClickOutside={setDateOpen("start_date")}
                        onClick={setDateOpen("start_date", true)}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("start_date", !isStartDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                  <hr className="hr-separator" />
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("End")}&nbsp;&nbsp;</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={startEndDate.end_date}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("End date")}
                        onChange={onDateChange("end_date")}
                        className="date-picker"
                        open={isEndDateOpen}
                        onClickOutside={setDateOpen("end_date")}
                        onClick={setDateOpen("end_date", true)}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("end_date", !isEndDateOpen)}
                        alt=""
                      />
                    </div> 
                  </div>
                </div>
              </div>

              <div className="action-btns" style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column"
                }}>
                  <div 
                    style={{
                      marginBottom : "7px"
                    }}
                  >
                    <button onClick={onSaveFilter} className="close-btn me-3" 
                      style={{
                        width : "100%",
                        color : isFilterActive ? "#8b8f94" : "#6353ea"
                      }}
                    >
                      {t("FixFilters")}
                    </button>
                  </div>
                  <div style={{
                    display:"flex"
                  }}>
                    <button onClick={onClearAll} className="close-btn me-3">
                      <img src={CloseIcon} alt="" />
                      {t("Clear")}
                    </button>
                    <button onClick={onApply} className="apply-btn">
                      {t("Apply")}
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RightSide;