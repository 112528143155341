// import React from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";

// const BarChart = () => {
//   const options = {
//     chart: {
//       type: "column",
//       backgroundColor: "transparent",
//     },
//     title: {
//       text: "Histogramme Coût produit / Margin",
//       style: {
//         color: "#6c757d",
//         fontSize: "18px",
//       },
//     },
//     xAxis: {
//       categories: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
//       labels: {
//         style: {
//           color: "#6c757d",
//           fontSize: "12px",
//         },
//       },
//     },
//     yAxis: {
//       min: 0,
//       title: {
//         text: null,
//       },
//       gridLineColor: "#e6e6e6",
//       labels: {
//         style: {
//           color: "#6c757d",
//           fontSize: "12px",
//         },
//       },
//     },
//     legend: {
//       align: "center",
//       verticalAlign: "top",
//       itemStyle: {
//         color: "#6c757d",
//         fontWeight: "bold",
//       },
//     },
//     tooltip: {
//       shared: true,
//       pointFormat:
//         '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y}€</b><br/>',
//     },
//     plotOptions: {
//       column: {
//         stacking: "normal",
//         borderRadius: 40, // Rounded bar corners
//         borderWidth: 0,
//       },
//       series: {
//         states: {
//           hover: {
//             enabled: true,
//             brightness: 0.1,
//           },
//         },
//       },
//     },
//     series: [
//       {
//         name: "Coût de production",
//         data: [492, 700, 600, 900, 800, 650, 750],
//         color: "#8758FF", // Purple color
//         borderRadiusTopLeft: "40px",
//         borderRadiusTopRight: "40px",
//       },
//       {
//         name: "Marge",
//         data: [508, 300, 400, 1000, 700, 550, 950],
//         color: "#54B435", // Green color
//         borderRadiusBottomLeft: "40px",
//         borderRadiusBottomRight: "40px",
//       },
//     ],
//   };

//   return <HighchartsReact highcharts={Highcharts} options={options} />;
// };

// export default BarChart;


import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";


const BarChart = ({data}) => {
  const { t } = useTranslation();

  const options = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: `${t("Product cost / margin histogram")}`,
      style: {
        color: "#6c757d",
        fontSize: "18px",
      },
    },
    xAxis: {
      categories: data?.meals,
      labels: {
        style: {
          color: "#6c757d",
          fontSize: "12px",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      gridLineColor: "#e6e6e6",
      labels: {
        style: {
          color: "#6c757d",
          fontSize: "12px",
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      itemStyle: {
        color: "#6c757d",
        fontWeight: "bold",
      },
    },
    tooltip: {
      shared: true,
      pointFormat:
        '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y}€</b><br/>',
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
        borderRadius: 10,
        // groupPadding: 0.3, // Adjust space between bars
        // pointPadding: 0.3, // Adjust space between categories
        pointWidth: 28, // Set bar width to 28px
      },
      series: {
        states: {
          hover: {
            enabled: true,
            brightness: 0.1,
          },
        },
      },
    },
    series: [
        {
            name: "Cost",
            data: data?.costs,
            color: "#54B435", // Green color
            borderRadiusTopLeft: 40, // Rounded corners on the top left
            borderRadiusTopRight: 40, // Rounded corners on the top right
        },
        {
            name: "Margin",
            data: data?.margins,
            color: "#8758FF", // Purple color
            // borderRadiusBottomLeft: 40, // Rounded corners on the bottom left
            // borderRadiusBottomRight: 40, // Rounded corners on the bottom right
        },
    ],
  };

  return (
    <div style={{ width: "95%", height: "100%", margin: "auto" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
   )
};

export default BarChart;
