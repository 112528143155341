import { get } from "lodash";

const NumberInput = ({ c, it, onInputChange, isTotalRow,currency }) => {
  return (
    <div className="btn-container" style={{ whiteSpace: "nowrap" }}>
      {!isTotalRow &&  <button className='custom-btn minus-btn' onDoubleClick={(e) => e.stopPropagation()} onClick={onInputChange(it)} name={c.dataField} value={parseInt(get(it, c.dataField))-1}>-</button>}
     <input
        name={c.dataField}
        type={isTotalRow ? "text" : c.dataField === "stock.unit_stock" ? "text" : "number"}
        value={`${get(it, c.dataField)}${isTotalRow ? currency.currencySymbol :  ""}`}
        className={`input-style ${isTotalRow ? "": "table-input-number" }`}
        onChange={onInputChange(it)}
        style={{ color: "inherit", border: "none" }}
        disabled={isTotalRow}
      />
      {!isTotalRow && <button className='custom-btn add-btn' onDoubleClick={(e) => e.stopPropagation()} name={c.dataField} value={parseInt(get(it, c.dataField))+1} onClick={onInputChange(it)}>+</button>}
    </div>
  );
};

export default NumberInput;