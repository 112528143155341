import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import request from "services/request";
import DeleteModal from "views/commonViews/DeleteModal";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";

import AddProviderModal from "./AddProviderModal";
import { getDummyProviders, providersKeys, requiredProvidersKeys } from "../utils.ts";

import EditIcon from "assets/images/icon/EDIT.svg";
import DeleteIcon from "assets/images/icon/DELETE.svg";
import Addicon from "assets/images/icon/ADD_WHITE.svg";
import leftSide from "views/forecast/leftSide";
import ImportModule from "../common/ImportIngredients";

function MyProviders() {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
    isLabo
  } = useUserData();
  const [addProviderModalShow, setAddProviderModalShow] = useState(false);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  const [importModalShow, setImportModalShow] = useState(false);
  const [selectedProvider, setSelectedRestaurant] = useState(null);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setProviders(getDummyProviders());
      return;
    }
    if (selectedRestaurantId !== "") {
      getProvidersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId]);

  const getProvidersData = async () => {
    setLoading(true);
    try {
      const result = await request.get(
        `providers?restaurant_id=${selectedRestaurantId}`
      );
      let _providers = result.providers;
      setProviders(_providers);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const onRowAdded = () => {
    getProvidersData()
  }

  const saveProvider = async (providerData) => {

    if (!selectedRestaurantId && !isLabo) {
      setAddProviderModalShow(false);
      setSampleRestaurantModal(true);
      return;
    }
    providerData.restaurant_id = selectedRestaurantId;

    setLoading(true);
    try {
      const result = await request.post("/providers", providerData);
      const data = await result.clone().json();

      // check for error response
      if (result.ok) {
        setAddProviderModalShow(false);
        setProviders([...providers, data]);
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const deleteProvider = async (providerId) => {
    setLoading(true);
    try {
      const result = await request.delete(`/providers/${providerId}`, {});
      const data = await result.clone().json();

      // check for error response
      if (result.ok) {
        setProviders(
          providers.filter((provider) => provider.id !== providerId)
        );
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const updateProvider = async (providerData) => {
    if (!selectedRestaurantId) {
      setAddProviderModalShow(false);
      setSampleRestaurantModal(true);
      return;
    }

    const {id, ...providersObj} = providerData;

    setLoading(true);
    try {
      const result = await request.patch(`/providers/${id}`, providersObj);
      const data = await result.clone().json();
      // check for error response
      if (result.ok) {
        setAddProviderModalShow(false);
        setProviders(
          providers.map((p) => ({ ...p, ...(p.id === data.id && data) }))
        );
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const item = (data, index) => (
    <Row key={index}>
      <Row className="pe-0">
        <Col lg={10}>
          <span className="provider-title">{data.name}</span>
        </Col>
        <Col className="icon-container" lg={2}>
          <img
            src={EditIcon}
            className="icon"
            alt=""
            onClick={() => {
              setSelectedRestaurant(data);
              setAddProviderModalShow(true);
            }}
          />
          <img
            src={DeleteIcon}
            className="icon"
            alt=""
            onClick={() => {
              if (!selectedRestaurantId) {
                setSampleRestaurantModal(true);
                return;
              }
              setSelectedRestaurant(data);
              setDeleteModelShow(true);
            }}
          />
        </Col>
      </Row>
      <Row>
        <span className="provider-sub-title">{t("ContactName")}</span>
        <span className="provider-text">{data.contact_name}</span>
        <span className="provider-sub-title">{t("Email")}</span>
        <span className="provider-text">{data.email_address}</span>
        <span className="provider-sub-title">{t("PhoneNumber")}</span>
        <span className="provider-text">{data.contact_number}</span>
        <span className="provider-sub-title">{t("Address")}</span>
        <span className="provider-text">{data.address}</span>
        {/* divider */}
        {index === providers.length - 1 ? (
          <></>
        ) : (
          <div className="divider"></div>
        )}
      </Row>
    </Row>
  );
  return (
    <div className="my-providers">
      {/* Email and name block */}

      <Row>
        <div className="d-flex row" style={{marginTop: "50px"}}>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("MyProviders")}</p>
          <p className="sub-title-text">{t("MyProvidersList")}</p>
        </Col>
        <Col  lg={{ offset: 4 }} style={{ width: "min-content",textAlign: "end"}}>
            <Button
              style={{paddingLeft: "15px"}}
              variant="primary add-btn-container"
              onClick={() => {
                setSelectedRestaurant(null);
                setAddProviderModalShow(true);
              }}
            >
              <img src={Addicon} className="add-btn-icon" alt="..." />
              {t("AddProvider")}
            </Button>
        </Col>
        <Col style={{width: "min-content", textAlign: "end"}}>
            <Button
              variant="primary add-btn-container"
              onClick={() => setImportModalShow(true)}
            >
              <img src={Addicon} className="add-btn-icon" alt="..." />
              {t("AddMultipleProvider")}
            </Button>
        </Col>
        </div>
      </Row>

      {/* <Row>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("MyProviders")}</p>
          <p className="sub-title-text">{t("MyProvidersList")}</p>
        </Col>
        <Col lg={{ offset: 6, span: 3 }}>
          <Button
            variant="primary add-provider-btn"
            onClick={() => {
              setSelectedRestaurant(null);
              setAddProviderModalShow(true);
            }}
          >
            <img src={Addicon} alt="" className="add-provider-btn-icon" />
            {t("AddProvider")}
          </Button>
        </Col>
      </Row> */}
      <Row>
        <Col className="" lg={{ offset: 3, span: 9 }}>
          {providers.map((data, index) => item(data, index))}
        </Col>
      </Row>
      <ImportModule 
        show={importModalShow} 
        modalName="Provider" 
        requestPath='/providers/create-in-bulk' 
        options={providersKeys} 
        requiredOptions={requiredProvidersKeys} 
        title='AddMultipleProviders' 
        onHide={setImportModalShow}
        onRowAdded={onRowAdded}
      />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModelShow(false)}
        onPositiveClicked={() => {
          setDeleteModelShow(false);
          deleteProvider(selectedProvider.id);
        }}
        modalData={{
          title: t("DeleteProvider"),
          description: t("DeleteProviderDescription"),
          positiveBtnTitle: t("DeleteProviderBtn"),
        }}
      />
      {addProviderModalShow ? (
        <AddProviderModal
          show={addProviderModalShow}
          onHide={() => setAddProviderModalShow(false)}
          saveProvider={(data) => saveProvider(data)}
          providerData={selectedProvider}
          updateProvider={(data) => updateProvider(data)}
        />
      ) : null}
    </div>
  );
}

export default MyProviders;
